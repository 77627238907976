import styled, { css, keyframes } from 'styled-components';
import media from '../../../styles/media';
import Footer from '../../Footer';

const slideIn = keyframes`
  from {
    transform: translate(0,-20px);
    opacity: 0;
  }

  to {
    transform: translate(0,0);
    opacity: 1;
  }
`;

export const TipsFooter = styled(Footer)`
  padding: 15px 0 0 0;

  ${media.lg`
    padding: 40px 0 0 0;
  `};
`;

export const WordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Word = styled.div`
  margin-bottom: 30px;
  padding: 10px 30px 10px 30px;
  font-size: 1em;
  font-weight: ${props => (props.isActive ? 600 : 'normal')};
  cursor: ${props => (props.isDisabled ? 'initial' : 'pointer')};
  opacity: ${props => (props.isDisabled && !props.isActive ? '0.5' : '1')};
  position: relative;

  width: 100%;

  ${media.md`
    width: 50%;
  `};

  .emoji{
    font-size:1.5em;
    transform: translate(0,3px);
    display: inline-block;
    line-height: 1em;
  }

  /* CHECKBOX */
  strong{
    color: ${props => props.theme.partgreen};
  }

  &:before{
    content:'';
    position: absolute;
    left: 0;
    top: 15px;
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.theme.partgreen};
  }

  ${props => props.isActive
    && css`
      &:after {
        content:'';
        border: 2px solid ${props.theme.partgreen};
        border-left: none;
        border-top: none;
        position: absolute;
        left: 7px;
        top: 19px;
        width: 6px;
        height: 10px;
        transform: rotate(40deg);
      }
    `};
`;

export const WizardStep = styled.div`
  padding: 20px 20px 0 20px;
  border: 1px solid ${props => props.theme.lightGray};
  animation: ${slideIn} 500ms ease-in-out;

  ${media.md`
  padding: 30px 30px 0 30px;
  `};
`;

export const WizardStepTitle = styled.h3`
  display:inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`;

export const WizardCounter = styled.div`
  width: 100%;
  text-align:center;
  margin-top: 20px;
`;

export const WizardCounterItem = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  border: 1px solid ${props => props.theme.primary};
  background: ${props => (props.isActive ? props.theme.primary : 'transparant')};
  cursor: pointer;
`;

export default WordContainer;
