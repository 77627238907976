import React from 'react';
import { Split } from '../../Split';
import {
  ChosenWordExplanation, StyledWordExplainer, WordContainer, StyledLabel,
} from './styles';

type WordpickerListExplainerProps = {
  title: String,
  subTitle: String,
  dependData: Array,
  answer: Array,
  setTip: Function,
  setAnswer: Function,
};

const emojis = {
  boos: '&#x1F621;',
  bang: '&#x1F630;',
  schuldig: '&#x1F616;',
  beschaamd: '&#x1F633;',
  onzeker: '&#x1F615;',
  machteloos: '&#x1F629;',
  verdrietig: '&#x1F622;',
}


const WordpickerListExplainer = ({
  answer = {},
  setAnswer,
  title,
  subTitle,
  setTip,
  dependData,
}: WordpickerListExplainerProps) => {
  const showQuestionWithEmotion = (word) => {
    const splitted = word.split('(');
    const emotion = splitted[1].split(')')[0];
    const wordWithEmotion = `${splitted[0]} <strong>(${splitted[1]} <span class="emoji">${emojis[emotion.toLowerCase()]}</span></strong>`;
    return wordWithEmotion;
  };

  return (
    <Split width={100}>
      <h2 className="question-heading">{title}</h2>
      <p className="question-description">{subTitle}</p>
      <WordContainer>
        {dependData.map(word => (
          <StyledWordExplainer key={word}>
            <ChosenWordExplanation>
              <StyledLabel dangerouslySetInnerHTML={{ __html: showQuestionWithEmotion(word) }} />
              <input
                value={answer[word]}
                id="explenation"
                type="text"
                onChange={(e) => {
                  const explenation = e.target.value;
                  const newValue = { ...answer, [word]: explenation };

                  setAnswer(
                    newValue,
                    Object.keys(newValue).filter(key => newValue[key] && newValue[key].length > 3)
                      .length === dependData.length,
                  );
                  if (setTip) setTip(newValue);
                }}
              />
            </ChosenWordExplanation>
          </StyledWordExplainer>
        ))}
      </WordContainer>
    </Split>
  )
};
export default WordpickerListExplainer;
