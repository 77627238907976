import React from 'react';
import classnames from 'classnames';

type IconProps = {
  name: String,
  className: String,
};

function Icon({ name, className }: IconProps) {
  return <i className={classnames(`icon-icon-${name}`, className)} />;
}

export default Icon;
