import React, { useEffect } from 'react';
import { connect } from '@cerebral/react';
import { state, signal } from 'cerebral/tags';

import Main from './Main';
import PartIntro from './PartIntro';
import PartSection from './PartSection';
import Poster from './Poster';
import NotFound from './NotFound';

const pageComponents = {
  main: Main,
  poster: Poster,
};

type pageProps = {
  currentPage: String,
  currentSubPage: String,
  parts: Array,
  init: Function,
};

const Page = ({
  currentPage, currentSubPage, parts, init,
}: pageProps) => {
  useEffect(() => {
    init();
  }, [init]);

  if (!parts) return null;

  let PageComponent = null;
  let currentPart = null;

  PageComponent = pageComponents[currentPage];
  if (PageComponent) return <PageComponent />;

  currentPart = parts.find(p => p.slug === currentPage);

  if (currentPart && currentSubPage) {
    const currentSection = currentPart.sections.find(s => s.slug === currentSubPage);

    if (currentSection) {
      return <PartSection part={currentPart} section={currentSection} />;
    }
  } else if (currentPart) {
    return <PartIntro {...currentPart} amount={parts.length} />;
  }

  return <NotFound />;
};

const stateSignals = {
  currentPage: state`app.currentPage`,
  currentSubPage: state`app.currentSubPage`,
  parts: state`app.data.parts`,
  init: signal`app.init`,
};

export default connect(
  stateSignals,
  Page,
);
