import styled from 'styled-components';

import { Split } from '../../components/Split';

export const SplitWrapper = styled(Split)`
  background-color: url('${props => props.theme.cdn}assets/bg-alt@2x.jpg');
`;

export const QuoteIntro = styled.div`
  /* img {
  float: right;
  width: 50%;
} */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  p {
    display: inline-block;
    flex: 1 1 auto;
  }

  .QuoteIntroImage {
    height: 400px;
    flex: 1 1 auto;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%
    }
  }
`;

export const IntroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .sub-heading {
    font-weight: 600;
    color: ${props => props.theme[`part${props.color}`]}
  }

  .heading {
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 10px;
    line-height: 1.2;
  }

  .description {
    font-weight: 300;
  }
`;

export const IntroContentWrapper = styled.div`

`;

export const ButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;
