import React from 'react';

import { AvatarForm } from './styles';
import { findAvatar, CDN } from '../../constants';

type PersonProps = {
  name: String,
  image: String,
  index: Number,
  handleChange: Function,
  handleRemove: Function,
};

export default ({
  name, image, index, handleChange, handleRemove,
}: PersonProps) => {
  const { src } = findAvatar(image);

  return (
    <AvatarForm>
      <div className="close" onClick={handleRemove} />
      {src.indexOf('http') < 0 ? (
        <img src={`${CDN}assets/avatar/${src}`} alt="avatar" />
      ) : (
        <img src={src} alt="avatar" />
      )}
      <label htmlFor={index}>Naam*:</label>
      <input id={index} value={name} onChange={e => handleChange(e.target.value)} />
    </AvatarForm>
  );
};
