import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';

import PartsOverview from '../components/PartsOverview';

type PartsOverviewContainerProps = {
  pages: Object,
};

const PartsOverviewContainer = ({ pages, ...props }: PartsOverviewContainerProps) => (
  <PartsOverview {...pages.main} {...props} />
);

const stateSignals = {
  answers: state`app.answers`,
  pages: state`app.data.pages`,
  parts: state`app.data.parts`,
  loading: state`app.loading`,
};

export default connect(
  stateSignals,
  PartsOverviewContainer,
);
