import styled from 'styled-components';
import media from '../../styles/media';

export const SubHeading = styled.h6`
  font-size: 2rem ;
  font-weight: 300;
  margin:0;
`;

export const Heading = styled.h3`
  font-weight: 500;
  font-size: 2rem;
  color: #000;
  margin: 0;
`;

export const Part = styled.a`
  pointer-events: ${props => (props.isFinished ? 'none' : 'initial')};
  transition: all 0.15s ease-out;
  display: block;
  padding: 20px;
  padding-top: 80px;
  border: 3px dashed ${props => props.theme[props.lightColor]};
  text-decoration: none;
  display: flex;
  flex-flow: column;
  position: relative;

  img {
    color:${props => props.theme[props.color]};
    font-size: 5rem;
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &:hover,
  &.finished {
    transition: all 0.025s ease-in;
    background-color: ${props => props.theme[props.lightColor]};

    ${SubHeading}, ${Heading} {
      color: #000;
    }

    i {
      color: black;
    }
  }
`;

export const PartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  

  a {
    width: 100%;
    ${media.sm`
     width: calc(50% - 20px);
    `};
    min-width: 160px;
    margin-bottom: 30px;
  }
`;

export default Part;
