import { CDN } from '../constants';

export default {
  cdn: CDN,

  primary: '#50C8E1',
  primaryLight: '#ECF3F5',
  // primaryDark: '#8D9525',
  secondary: '#A0D2DC',
  dark: '#000000',

  fontFamily: 'Niveau Grotesk, sans-serif',
  fontSizeBase: '16px',
  lineHeightBase: '1.75',
  color: '#000000',
  headingsColor: '#3A3A3C',
  headingsFontWeight: '500',

  btnFontSize: '1.8rem',
  btnFontWeight: '400',
  btnPaddingX: '15px',
  btnPaddingY: '14px',
  btnBorderRadius: '3px',

  btnSmallFontSize: '0.7em',
  btnSmallPaddingX: '10px',
  btnSmallPaddingY: '8px',

  partgreen: '#82C8A5',
  partlightgreen: '#B4DCBE',
  partblue: '#50C8E1',
  partlightblue: '#A0D2DC',
  partorange: '#FF8C6E',
  partlightorange: '#F5AF96',
  partyellow: '#FFBE64',
  partlightyellow: '#FFDCA0',
  partpink: '#DCAAC8',
  partlightpink: '#ECD0DE',
  partpurple: '#968CDC',
  partlightpurple: '#BEB4DC',

  white: '#fff',
  gray: '#DCDDE4',
  lightGray: '#F1F1F1',
  border: '#BABBC5',

  partner: '#E64FA3',
  personal: '#45AECD',

  spacer: 15,
};
