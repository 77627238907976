export default [
  {
    path: '/',
    signal: 'app.homeRouted',
  },
  {
    path: '/:page',
    signal: 'app.pageRouted',
  },
  {
    path: '/:page/:subPage',
    signal: 'app.subPageRouted',
  },
];
