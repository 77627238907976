import React, { useState } from 'react';

import { Splitview, Split } from '../../Split';
import Icon from '../../Icon';

import { StatementCounter, Statement } from '../../Slider/styles';
import { StyledForm, StyledLabel, StyledResult } from './styles';

type MultipleChoiceProps = {
  title: String,
  id: Number,
  radiobuttons: Array,
  answer: Object,
  setAnswer: Function,
  quote: String,
  setTip: Function,
  showTipInChecklistWhen: String
};

const MultipleChoice = ({
  title, id, radiobuttons, setAnswer, answer = '', setTip, quote, showTipInChecklistWhen,
}: MultipleChoiceProps) => {
  const [showResult, setShowResult] = useState(false);

  const getExplanation = () => {
    const selectedChoice = radiobuttons.filter(choice => choice.value === answer);
    if (selectedChoice[0]) {
      return <div dangerouslySetInnerHTML={{ __html: selectedChoice[0].explanation }} />;
    }
    return '';
  };

  const getNotification = () => {
    const selectedChoice = radiobuttons.filter(choice => choice.value === answer);
    if (selectedChoice[0] && typeof selectedChoice[0].notification !== 'undefined') {
      return (
        <div className="alert alert-info">
          <p>
            <Icon name="lamp" />
            <span className="alert-italic" dangerouslySetInnerHTML={{ __html: selectedChoice[0].notification }} />
          </p>
        </div>
      );
    }
    return '';
  };

  return (
    <Splitview>
      <Split width={50} left border>
        {id && (
          <StatementCounter>
            <span>
              {`Stelling ${id} /8`}
            </span>
          </StatementCounter>
        )}
        <Statement>{title}</Statement>
      </Split>
      <Split width={50}>
        {showResult
          ? (
            <StyledResult>
              <h3>
                Je bent het&nbsp;
                <strong>{answer.toLowerCase()}</strong>
                &nbsp;met deze stelling
              </h3>
              {getExplanation()}
              {getNotification()}
            </StyledResult>
          ) : (
            <StyledForm>
              {radiobuttons.map(button => (
                <StyledLabel htmlFor={`multiple-${button.id}`} key={button.value}>
                  <input
                    id={`multiple-${button.id}`}
                    type="radio"
                    name="multiplechoice[]"
                    value={button.value}
                    onChange={(evt) => {
                      const newAnswer = evt.target.value;
                      setAnswer(newAnswer, true);
                      setShowResult(true);

                      if (evt.target.value === showTipInChecklistWhen) {
                        const tipHhtml = `<div><p>${title}<br/><br/><span>"${quote}"</span></p></div>`;
                        // const tipHhtml = `<div><p>${title}: <strong>${evt.target.value}</strong></p><br/><span>"${quote}"</span></div>`;
                        setTip(tipHhtml);
                      }
                      // else {
                      //   const tipHhtml = `<div><p>${title}: <strong>${evt.target.value}</strong></p></div>`;
                      //   setTip(tipHhtml);
                      // }
                    }}
                  />
                  <span>{button.value}</span>
                </StyledLabel>
              ))}
            </StyledForm>
          )
        }
      </Split>
    </Splitview>
  );
};

export default MultipleChoice;
