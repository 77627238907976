import { set, when } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';

import fetchData from '../actions/fetchData';
import fetchId from '../actions/fetchId';
import fetchState from '../actions/fetchState';
// import loadTestData from '../actions/loadTestData';

export default [
  set(state`app.loading`, true),
  fetchData,
  {
    success: [set(state`app.data`, props`data`)],
    error: [],
  },
  when(state`app.id`),
  {
    false: [
      fetchId,
      {
        success: [],
        error: [],
      },
    ],
    true: [
      fetchState,
      {
        success: [],
        error: [],
      },
    ],
  },
  set(state`app.loading`, false),
];
