import React from 'react';

import PartIcon from '../PartIcon';
import {
  Part, PartContainer, SubHeading, Heading,
} from './styles';

type PartsPickerProps = {
  parts: Array,
};

const PartsPicker = ({ parts }: PartsPickerProps) => (
  <PartContainer>
    {parts.map(({
      slug, icon, part, title, isFinished, color,
    }) => (
      <Part
        key={slug}
        href={`#/${slug}`}
        isFinished={isFinished}
        color={`part${color}`}
        lightColor={`partlight${color}`}
        className={isFinished ? 'finished' : ''}
      >
        <PartIcon name={icon} />
        <SubHeading>
            Deel
          {part}
        </SubHeading>
        <Heading color={`part${color}`}>{title}</Heading>
      </Part>
    ))}
  </PartContainer>
);

export default PartsPicker;
