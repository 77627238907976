import styled from 'styled-components';
import media from '../../styles/media';

export const AvatarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Avatar = styled.div`
  /* width: 170px;
  height: 170px; */
  width: 33.3333%;
  padding: 4px;

  ${media.md`
  width: 25%;`};

  cursor: pointer;

  border: 3px solid ${props => (props.isChosen ? props.theme.primary : 'transparent')};

  img {
    width: 100%;
    height: auto;
  }
`;

export default AvatarContainer;
