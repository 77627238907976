import { Controller } from 'cerebral';
import Devtools from 'cerebral/devtools';

import Router from './router';
import App from './modules/app';
import providers from './providers';

const controller = Controller({
  providers: [
    function Context(context) {
      context.api = providers.api; // eslint-disable-line
      return context;
    },
  ],
  devtools: process.env.NODE_ENV === 'production' ? null : Devtools({ host: 'localhost:39000' }),
  modules: {
    router: Router,
    app: App,
  },
});

export default controller;
