import React, { useState } from 'react';
import { state, signal } from 'cerebral/tags';
import { connect } from '@cerebral/react';

import { Splitview, Split } from '../components/Split';
import Page from '../components/Page';
import PosterComponent from '../components/Poster';
import isValidEmail from '../utils/isValidEmail';
import Button from '../components/Button';
import Icon from '../components/Icon';

type PosterProps = {
  id: String,
  PDFMailed: Function,
  homeRouted: Function,
};

const Poster = ({ id, PDFMailed, homeRouted }: PosterProps) => {
  const [isSend, setIsSend] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <Page>
      <Splitview>
        <Split left background="primaryLight">
          <PosterComponent id={id} />
        </Split>
        <Split right>
          <Button style={{ marginBottom: '1em' }} light color="blue" onClick={() => homeRouted()}>
            <Icon name="previous" />
            {' '}
            Terug naar de oefeningen
          </Button>
          <h3>Jouw checklist</h3>
          <p>
            We hebben op basis van de oefeningen een checklist voor jou samengesteld. Met
            {' '}
            <strong>geheugensteuntjes, uitleg en tips</strong>
            . Helemaal op maat van wat je zelf
                        invulde.
          </p>
          <p>
            <strong>Enkel jij</strong>
            {' '}
            kan deze checklist zien. Klik op de knop ‘Bekijk je
                        checklist' als je hem wil downloaden of printen. Zo kan je hem bijhouden en er
                        bijvoorbeeld later nog eens op terugblikken.
          </p>
          <p>
            Denk even aan je privacy. Wil je liever niet dat iemand het blad dat je hebt geprint of
            gedownload op de computer kan terugvinden? Heb je een mailadres waar niemand anders
            toegang toe heeft? Dan kan je de checklist ook naar jezelf mailen.
          </p>
          <p>
            Lees hoe De Druglijn zorg draagt voor jouw
            {' '}
            <a href="https://www.druglijn.be/privacy" rel="noopener noreferrer" target="_blank">
              privacy en anonimiteit
            </a>
            .
          </p>
          <p>Vul daarvoor hieronder je e-mailadres in.</p>

          <form className="form-inline">
            <input
              type="email"
              id="inlineFormInput"
              className="form-control"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <Button
              type="submit"
              disabled={!isValidEmail(email)}
              onClick={(e) => {
                e.preventDefault();
                if (isValidEmail(email)) {
                  setIsSend(true);
                  PDFMailed({
                    email,
                  });
                }
              }}
            >
              {isSend ? 'Verstuur opnieuw' : 'Verstuur via e-mail'}
            </Button>
          </form>
        </Split>
      </Splitview>
    </Page>
  );
};

const stateSignals = {
  id: state`app.id`,
  PDFMailed: signal`app.PDFMailed`,
  homeRouted: signal`app.homeRouted`,
};

export default connect(
  stateSignals,
  Poster,
);
