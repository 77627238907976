import React from 'react';

import { StyledClickableLink } from './styles';

type ClickableLinkProps = {
  children: any,
};

const ClickableLink = ({ children, ...props }: ClickableLinkProps) => (
  <StyledClickableLink {...props}>{children}</StyledClickableLink>
);

export default ClickableLink;
