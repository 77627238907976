import signals from './signals';
import state from './state';

export default (props) => {
  const stateItems = typeof state === 'function' ? state(props) : state;
  return {
    state: stateItems,
    signals,
  };
};
