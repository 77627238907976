import React, { useEffect } from 'react';
import {
  NetworkRadarContainer,
  RadarPerson,
  RadarLabel,
  RadarFamily,
  RadarFriends,
  RadarOther,
} from './styles';

import { Splitview, Split } from '../../Split';

import Avatar from '../../Avatar';

type NetworkOverviewProps = {
  title: String,
  dependData: Object,
};

const NetworkOverview = ({ title, dependData }: NetworkOverviewProps) => {
  const { family = [], friends = [], others = [] } = dependData;

  const calculatePositions = () => {
    document.querySelectorAll('.radar-circle').forEach((circle) => {
      const parent = circle.getBoundingClientRect();

      const { plots } = circle.dataset;
      const increase = (Math.PI * 2) / plots;
      let angle = parseInt(circle.dataset.angle);

      const factorWidth = typeof circle.dataset.factor !== 'undefined' ? parseFloat(circle.dataset.factor) : 0.5;

      const elipseWidth = parent.width * factorWidth; // todo calc ratio
      const elipseHeight = parent.height * factorWidth; // todo calc ratio
      const centerX = parent.width * 0.5;
      const centerY = parent.height * 0.5;

      circle.querySelectorAll('.radar-person').forEach((person) => {
        const x = elipseWidth * Math.cos(angle) + centerX;
        const y = elipseHeight * Math.sin(angle) + centerY;
        const xoffset = person.getBoundingClientRect().width * 0.5;
        const yoffset = person.getBoundingClientRect().height * 0.5;

        person.style.left = `${x - xoffset}px`;
        person.style.top = `${y - yoffset}px`;

        angle += increase;
      });
    });
  };

  useEffect(() => {
    calculatePositions();
    const handleResize = () => calculatePositions();
    window.addEventListener('resize', calculatePositions);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Splitview>
      <Split width={100}>
        <h2 className="question-heading">{title}</h2>
        <NetworkRadarContainer>
          <RadarFamily
            className="radar-circle radar-circle--family"
            data-plots={family.length}
            data-factor="0.33"
            data-angle={Math.floor(Math.random() * 40) + 20}
          >
            <RadarLabel left>Familie</RadarLabel>
            {family.map(person => (
              <RadarPerson key={person.image} className="radar-person">
                <Avatar
                  {...person}
                  slug={person.image}
                  key={person.image}
                  className="radar-person__avatar"
                />
              </RadarPerson>
            ))}
          </RadarFamily>
          <RadarFriends
            className="radar-circle radar-circle--friends"
            data-plots={friends.length}
            data-factor="0.4"
            data-angle={Math.floor(Math.random() * 35) + 5}
          >
            <RadarLabel>Vrienden, collega's, kennissen</RadarLabel>
            {friends.map(person => (
              <RadarPerson key={person.image} className="radar-person">
                <Avatar
                  {...person}
                  slug={person.image}
                  key={person.image}
                  className="radar-person__avatar"
                />
              </RadarPerson>
            ))}
          </RadarFriends>
          <RadarOther
            className="radar-circle radar-circle--other"
            data-plots={others.length}
            data-factor="0.42"
            data-angle={Math.floor(Math.random() * 40) + 1}
          >
            <RadarLabel left>Andere</RadarLabel>
            {others.map(person => (
              <RadarPerson key={person.image} className="radar-person">
                <Avatar
                  {...person}
                  slug={person.image}
                  key={person.image}
                  className="radar-person__avatar"
                />
              </RadarPerson>
            ))}
          </RadarOther>
        </NetworkRadarContainer>
      </Split>
    </Splitview>
  );
};
export default NetworkOverview;
