import React from 'react';

import {
  StyledProgressInfo, StyledTrack, StyledProgress, StyledProgressBar,
} from './styles';
// import Icon from '../Icon';

type ProgressBarProps = {
  progress: Number,
  total: Number,
  color: String,
};
export default ({ progress, total, color }: ProgressBarProps) => (
  <StyledProgressBar>
    <StyledTrack background={`partlight${color}`}>
      <StyledProgress progress={progress} total={total} />
    </StyledTrack>
    <StyledProgressInfo isCompleted={progress === total}>
      {' '}
      {progress}
        /
      {total}
    </StyledProgressInfo>
  </StyledProgressBar>
);
