import styled, { css } from 'styled-components';
import media from '../../styles/media';

export const Splitview = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${media.lg`
    flex-direction: row;
  `};
`;

export const Split = styled.div`
  background-color: ${props => (props.background && props.theme[props.background]) || props.theme.white};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};

  padding: ${props => (props.padding === undefined ? '20px' : props.padding)};

  ${props => props.background
    && css`
      color: #fff;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #000;
      }
    `};

  ${media.lg`
      width: ${props => props.width || 50}%;
      height: ${props => `${props.height}%` || 'auto'};
      padding: ${props => (props.padding === undefined ? '40px' : props.padding)};
      ${props => props.left
        && props.border
        && css`
          border-right: dashed 2px ${props.theme.gray};
        `};
    `};
`;

export const SplitContainer = styled.div`
  position: relative;
  width: 100%;
`;
