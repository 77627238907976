import { scroller } from 'react-scroll';

export default function () {
  scroller.scrollTo('anchor', {
    duration: 200,
    delay: 0,
    smooth: true,
    offset: -50,
  });
}
