import Router from '@cerebral/router';
import routes from './routes';

export default Router({
  // Define routes and point to signals
  routes,
  onlyHash: true,
  allowEscape: true,
  baseURL: '/grip/ouder-van',
});
