export default async function mailPdf({
  api, state, props, path,
}) {
  const { id } = state.get('app');
  const { email } = props;

  try {
    await api.post(`/state/${id}/mail-pdf`, {
      email,
    });

    return path.success();
  } catch ({ message }) {
    return path.error({ error: message });
  }
}
