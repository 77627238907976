import React from 'react';
import { connect } from '@cerebral/react';
import { state, signal } from 'cerebral/tags';
import { Document, Page, pdfjs } from 'react-pdf';

import { API_URL } from '../../constants';
import Loader from '../Loader';
import { StyledPoster, StyledPreview } from './styles';
import StyledButton from '../Button/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PosterProps = {
  id: String,
  eventTracked: Function,
};

const Poster = ({ id, eventTracked }: PosterProps) => (
  <StyledPoster>
    <StyledPreview>
      {id && (
        <Document
          loading={<Loader />}
          noData={
            <p>Er ging iets mis bij het laden van de PDF, probeer het later nog eens opnieuw.</p>
          }
          file={`${API_URL}/state/${id}/pdf-inline`}
        >
          <Page width={350} pageNumber={1} />
        </Document>
      )}
    </StyledPreview>

    <a
      href={`${API_URL}/state/${id}/pdf-inline`}
      onClick={() => {
        eventTracked({
          eventAction: 'pdf_downloaded',
        });
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledButton light>Bekijk je checklist</StyledButton>
    </a>
  </StyledPoster>
);

const stateSignal = {
  id: state`app.id`,
  eventTracked: signal`app.eventTracked`,
};

export default connect(
  stateSignal,
  Poster,
);
