import React from 'react';
import { CDN } from '../../constants';

type PartIconProps = {
  name: String,
  className: String,
};

function PartIcon({ name, className }: PartIconProps) {
  // return <i className={classnames(`bol-icon-${name}`, className)} />;
  return <img src={`${CDN}assets/part-icons/${name}.png`} height={85} width={85} alt={name} className={className} />;
}

export default PartIcon;
