import styled from 'styled-components';
import media from '../../styles/media';

export const StyledQuote = styled.p`
  font-size: 1.125em;
  line-height: 1.555555556;
  font-weight: 500;
  padding: 40px 20px;

  color: black;

  text-align: center;

  ${media.md`
  font-size: 2.25em;
  padding: 40px;
  `};

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

export default StyledQuote;
