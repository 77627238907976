import React from 'react';

import PartsOverviewContainer from '../containers/PartsOverview';
import Page from '../components/Page';
import SaveProgress from '../components/SaveProgress';

const Main = () => (
  <>
    <Page>
      <PartsOverviewContainer />
    </Page>
    <SaveProgress />
  </>
);

export default Main;
