import homeRouted from './signals/homeRouted';
import pageRouted from './signals/pageRouted';
import subPageRouted from './signals/subPageRouted';
import init from './signals/init';
import answerSet from './signals/answerSet';
import triggerFirstTime from './signals/triggerFirstTime';
import answerSubmitted from './signals/answerSubmitted';
import PDFMailed from './signals/PDFMailed';
import partFinished from './signals/partFinished';
import setTip from './signals/setTip';
import setRedFlag from './signals/setRedFlag';
import mailId from './signals/mailId';
import eventTracked from './signals/eventTracked';
import answerUnset from './signals/answerUnset';

export default {
  homeRouted,
  pageRouted,
  subPageRouted,
  init,
  answerSet,
  triggerFirstTime,
  answerSubmitted,
  PDFMailed,
  partFinished,
  setTip,
  setRedFlag,
  mailId,
  eventTracked,
  answerUnset,
};
