import React from 'react';

import { Splitview, Split } from '../../Split';
import { Statement, StatementSubtitle } from '../../Slider/styles';
import Icon from '../../Icon';

type EndscreenProps = {
  title: String,
  subTitle: String,
  description: String,
  notification: String,
  redFlag: String,
  columnType: String,
};

const Slider = ({
  title,
  subTitle,
  description,
  notification = '',
  redFlag,
  columnType = 'split',
}: EndscreenProps) => (
  <Splitview>
    {columnType === 'split' && (
    <Split width={30} left border>
      <Statement>{title}</Statement>
      <StatementSubtitle>{subTitle}</StatementSubtitle>
    </Split>
    )}
    <Split width={columnType === 'single' ? 100 : 70}>
      {columnType === 'single' ? (
        <>
          <Statement>{title}</Statement>
          <StatementSubtitle>{subTitle}</StatementSubtitle>
        </>
      ) : (
        <Statement placeholder width={36.5} dangerouslySetInnerHTML={{ __html: title }} />
      )}
      <p dangerouslySetInnerHTML={{ __html: description }} />
      {redFlag && <p className="red-flag" dangerouslySetInnerHTML={{ __html: redFlag }} />}
      {notification !== ''
          && (
          <div className="alert alert-info">
            <p>
              <Icon name="lamp" />
              <span className="alert-italic" dangerouslySetInnerHTML={{ __html: notification }} />
            </p>
          </div>
          )
        }
    </Split>
  </Splitview>
);

export default Slider;
