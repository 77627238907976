import styled from 'styled-components';
import media from '../../styles/media';

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  z-index: 1;

  padding: 15px;
  padding-top: 0;

  div {
    display: flex;
    align-items: center;
    flex: 1 1 0;

    &.footer-left {
      text-align: left;
      justify-content: flex-start;
    }

    &.footer-center {
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      text-align: center;
      justify-content: center;
    }

    &.footer-right {
      margin-top: 60px;
      width: 50%;
      text-align: right;
      justify-content: flex-end;
      flex-flow: column-reverse;
      align-items: flex-end;
      gap: 16px;

      ${media.md`
        flex-flow: row;
      `};

    }
    &.footer-left {
      margin-top: 60px;
      width: 50%;
    }
    ${media.md`
        flex: 1 1 0;

        &.footer-left {
            margin-top:0;
            order:1;
        }

        &.footer-center {
            position:static;
            order:2;
        }
        &.footer-right {
            margin-top:0;
            order:3;
        }
    `};
  }

  ${media.lg`
    padding: 40px;
    padding-top: 0;
  `};
`;

export default StyledFooter;
