import styled from 'styled-components';
import media from '../../styles/media';

export const StyledSaveProgress = styled.div`
  width: 100%;
  margin: 0 0 25px 0;
  padding: 40px 20px;

  background-color: #CBE4E9;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  

    ${media.md`
    flex-flow: row;
    align-items: center;
    padding: 40px 70px;
  `};

  h6 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  form {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-end;

    flex-flow: column;
    gap: 10px;

    ${media.md`
    flex-flow: row;
  `};

    button {
      margin-left: 10px;
      background-color: #A0D2DC;
      border-radius: 50px;
      padding: 15px;
      border: none;
    }

    input {
      border: none;
      border-radius: 50px;
    }
  }

  button {
    align-self: center;
    vertical-align: initial !important;
  }

  ${media.lg`
    margin: 0;
    width: 100%;
  `};
`;

export default StyledSaveProgress;
