import React from 'react';

import {
  StyledHeader, StyledPartInfo, StyledPartSubHeading, StyledPartHeading,
} from './styles';
import ProgressBar from '../ProgressBar';

type HeaderProps = {
  part: String,
  partColor: String,
  partNumber: Number,
  progress: Number,
  total: Number,
};

const Header = ({
  partNumber, part, partColor, progress, total,
}: HeaderProps) => (
  <StyledHeader background={`part${partColor}`}>
    <StyledPartInfo background={`partlight${partColor}`}>
      <StyledPartSubHeading>{`deel ${partNumber}`}</StyledPartSubHeading>
      <StyledPartHeading>{part}</StyledPartHeading>
    </StyledPartInfo>
    <ProgressBar progress={progress} total={total} color={partColor} />
  </StyledHeader>
);

export default Header;
