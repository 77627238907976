export default ({ depend, answers, part }) => {
  let dependData = {};
  if (depend) {
    if (Array.isArray(depend)) {
      depend.forEach((key) => {
        dependData[key] = answers[part.slug] && answers[part.slug][key];
      });
    } else {
      dependData = answers[part.slug] && answers[part.slug][depend];
    }
  }

  return dependData;
};
