import { set } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';

import mailPdf from '../actions/mailPdf';
import trackEvent from '../actions/trackEvent';

export default [
  set(state`app.mail.loading`, true),
  set(state`app.mail.error`, null),
  mailPdf,
  {
    success: [set(props`eventAction`, 'pdf_mailed'), trackEvent],
    error: [set(state`app.mail.error`, props`error`)],
  },
  set(state`app.mail.loading`, false),
];
