import styled, { css } from 'styled-components';
// import { darken } from 'polished';
import media from '../../styles/media';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  padding: ${props => props.theme.btnPaddingY}; ${props => props.theme.btnPaddingX};
  border-radius: ${props => props.theme.btnBorderRadius};
  font-size: ${props => props.theme.btnFontSize};
  font-weight: ${props => props.theme.btnFontWeight};
  text-align: start;
  color: ${props => props.theme.black};
 
  text-decoration-line:  underline ;
  text-decoration-color: ${props => (props.color ? props.theme[`part${props.color}`] : props.theme.primary)};
  text-decoration-style: solid ;
  text-decoration-thickness: 2px ;

  -webkit-text-decoration-line:  underline ;
  -webkit-text-decoration-color: ${props => (props.color ? props.theme[`part${props.color}`] : props.theme.primary)};
  -webkit-text-decoration-style: solid ;
  -webkit-text-decoration-thickness: 2px ;
  
  transition: background-color 75ms ease;
  will-change: background-color;

  background-color: ${props => props.theme[`part${props.color}`]};
  transition: all .2s ease;
  transform-origin: center;
  &:hover {
    transition: all .2s ease;
    transform: scale(1.03);
    i {
      transform-origin: center;
      transform: rotate(-45deg);
      transition: all .2s ease;
    }
  }

  opacity: ${props => (props.disabled ? '0.65' : '1')};

  font-family: ${props => props.theme.fontFamily};

  ${props => props.secondary
    && css`
      color: #fff;
      background-color: ${props.theme.green};
    `};

  ${props => props.tertiary
    && css`
      color: ${propsDashed => propsDashed.theme[`part${props.color}`]};
      background: white;
      border: 2px solid ${propsDashed => propsDashed.theme[`partlight${props.color}`]};
      text-transform: none;
      outline-color: #feffee;
    `};

  ${props => props.light
    && css`
      color: ${props.theme.dark};
      background-color: #fff;
      text-decoration: none;
    `};

  ${props => props.bare
    && css`
      color: ${(props.theme.color)};
      background-color: transparent;
      text-transform: none;
      font-weight: 400;
      padding: 0 0 4px 0;
      border-radius: 0;
      text-decoration-line:  underline ;
      text-decoration-color: ${(props.color ? props.theme[`part${props.color}`] : props.theme.primary)};
      text-decoration-style: solid ;
      text-decoration-thickness: 2px ;

      -webkit-text-decoration-line:  underline ;
      -webkit-text-decoration-color: ${(props.color ? props.theme[`part${props.color}`] : props.theme.primary)};
      -webkit-text-decoration-style: solid ;
      -webkit-text-decoration-thickness: 2px ;
      transition: padding 250ms ease-in-out;
      position:relative;

      i {
        position:absolute;
        left:-25px;
        top:45%;
        transform:translate(0,-50%);
        font-size:1em;
      }
    `};

  ${props => props.dashed
    && css`
      color: ${propsDashed => propsDashed.theme[`part${props.color}`]};
      background: white;
      border: 2px dashed ${propsDashed => propsDashed.theme[`part${props.color}`]};
      text-transform: none;
      outline-color: #feffee;
    `};

  ${props => props.small
    && css`
      font-size: ${props.theme.btnSmallFontSize};
      padding: ${props.theme.btnSmallPaddingY}; ${props.theme.btnSmallPaddingX};
    `};

  i {
    font-size: 1.2rem;
    &.icon-icon-next {
      margin-left: 10px;
      padding: 10px;
      border-radius: 50%;
      color: white;
      background-color: ${props => props.theme[`part${props.color}`]};

      ${media.lg`
      padding: 20px;
    `};
    }
    &.icon-icon-previous {
      margin-right: 10px;
      padding: 10px;
      border-radius: 50%;
      color: white;
      background-color: ${props => props.theme[`part${props.color}`]};

      ${media.lg`
      padding: 20px;
    `};
    }
  };
`;

export default StyledButton;
