export default async function ({ state, props }) {
  const parts = state.get('app.data.parts');
  const partIndex = parts.findIndex(({ slug }) => slug === props.part);
  const part = parts[partIndex];

  state.set('app.data.parts', [
    ...parts.slice(0, partIndex),
    { ...part, isFinished: true },
    ...parts.slice(partIndex + 1),
  ]);
}
