import React, { useState, useEffect } from 'react';

import { Split } from '../../Split';
import Button from '../../Button';

import WordContainer, {
  Word, WizardStep, WizardStepTitle, TipsFooter,
} from './styles';

type WizardPickerProps = {
  title: String,
  subTitle: String,
  // words: Array,
  redFlags: Object,
  answer: Array,
  setAnswer: Function,
  isModalOpen: Boolean,
  toggleModal: Function,
  setTip: Function,
  setRedFlag: Function,
  dependData: Array,
  modalContent: String,
  boards: Array,
  nextButton: any,
  centerButton: any,
  previousButton: any,
};

const WizardPicker = ({
  title,
  subTitle,
  answer = [],
  setAnswer,
  setTip,
  dependData,
  boards,
  nextButton,
  centerButton,
  previousButton,
}: WizardPickerProps) => {
  // const [words, setWords] = useState([...new Set(initialWords.concat(answer))]);
  // const [words, setWords] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [visibleIndex, setVisibleIndex] = useState(0);

  const getslug = (word) => {
    const splitted = word.split('(');

    let slug = '';

    if (splitted[1]) {
      slug = splitted[1].split(')')[0];
    }

    return slug;
  };

  const getTitleWithSteps = board => `${board} (${visibleIndex + 1}/${dependData.length})`;

  useEffect(() => {
    if (answer.length > visibleIndex) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [answer.length, visibleIndex]);

  return (
    <Split width={100}>
      {title && <h2 className="question-heading">{title}</h2>}
      {subTitle && (
        <div className="question-description">
          <p dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>
      )}
      {dependData.map((word, index) => {
        const slug = getslug(word);
        const boardItem = boards.find(item => item.slug === slug.toLowerCase());

        if (index !== visibleIndex) {
          return '';
        }

        return (
          <WizardStep key={boardItem.slug}>
            <WizardStepTitle dangerouslySetInnerHTML={{ __html: getTitleWithSteps(boardItem.title) }} />
            <WordContainer>
              {boardItem.items.map(boardWord => (
                <Word
                  key={`${slug}-${boardWord}`}
                  isDisabled={boardItem.max !== null && answer.filter(answerWord => answerWord.slug === slug).length >= boardItem.max}
                  isActive={answer.find(answerWord => answerWord.slug === slug && answerWord.word === boardWord)}
                  onClick={() => {
                    if (answer.find(answerWord => answerWord.slug === slug && answerWord.word === boardWord)) {
                      const boardIndex = answer.findIndex(w => w.slug === slug && w.word === boardWord);
                      const newValue = [...answer.slice(0, boardIndex), ...answer.slice(boardIndex + 1)];
                      setAnswer(newValue, answer.length + 1 === dependData.length);

                      if (setTip) setTip(newValue);
                    } else if (boardItem.max === null || answer.filter(answerWord => answerWord.slug === slug).length < boardItem.max) {
                      const objBoardWord = {
                        slug,
                        word: boardWord,
                      };
                      setAnswer([...answer, objBoardWord], answer.length + 1 === dependData.length);
                      if (setTip) setTip([...answer, objBoardWord]);
                    }
                  }}
                >
                  <span className="word" dangerouslySetInnerHTML={{ __html: boardWord }} />
                </Word>
              ))}
            </WordContainer>
          </WizardStep>
        );
      })}

      <TipsFooter>
        <div className="footer-center">{centerButton}</div>
        {dependData.length > 0 && (
          <div className="footer-left">
            {visibleIndex === 0 ? (
              previousButton
            ) : (
              <Button
                disabled={visibleIndex === 0}
                onClick={() => setVisibleIndex(visibleIndex - 1)}
                light
                color="green"
                direction="previous"
              >
                  Vorige tip
                {' '}
              </Button>
            )}
          </div>
        )}
        {dependData.length > 0 && (
          <div className="footer-right">
            {visibleIndex === dependData.length - 1 ? (
              nextButton
            ) : (
              <Button
                disabled={!isValid}
                onClick={() => setVisibleIndex(visibleIndex + 1)}
                light
                color="green"
                direction="next"
              >
                  Volgende tip
              </Button>
            )}
          </div>
        )}
      </TipsFooter>
      {/* <NewWordModal
        onClose={toggleModal}
        onAddWord={(word) => {
          setWords([...words, word]);
          if (max === null || answer.length < max) {
            setAnswer([...answer, word], true);
          }
          toggleModal();
        }}
        content={modalContent}
        isOpen={isModalOpen}
      /> */}
    </Split>
  );
};

export default WizardPicker;
