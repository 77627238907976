import React, { useState, useEffect, useRef } from 'react';

import Button from '../Button';
import Modal, { ModalBody, ModalHeader } from '../Modal';

import NewWordModalWrapper, { Form } from './styles';

type NewWordModalProps = {
  isOpen: Boolean,
  content: String,
  onAddWord: Function,
  onClose: Function,
  button: String,
};

const NewWordModal = ({
  isOpen,
  content,
  onAddWord,
  onClose,
  button = '+ toevoegen',
}: NewWordModalProps) => {
  const input = useRef();
  const [value, setValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });

  if (!isOpen) {
    return null;
  }

  return (
    <NewWordModalWrapper>
      <Modal onClose={onClose} className="modal-new-word">
        <ModalHeader>
          <h6>Voeg zelf iets toe</h6>
          <p>{content}</p>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="form-group">
              <input
                ref={input}
                className="form-control"
                type="text"
                name="new-word"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setIsDisabled(e.target.value.length <= 3);
                }}
                maxLength={50}
              />
              <small className="form-text text-muted float-right">
                {value.length}
                /50
              </small>
            </div>
            <Button
              disabled={isDisabled}
              onClick={(e) => {
                e.preventDefault();
                onAddWord(value);
                setValue('');
              }}
            >
              {button}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </NewWordModalWrapper>
  );
};

export default NewWordModal;
