import styled from 'styled-components';
import media from '../../styles/media';

export const PartsIntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: right bottom;
`;

export const PartsIntroSpeechBubble = styled.div`
  text-align: right;
  flex-grow: 1;
  max-height: 132px;

  img {
    height: 100%;
    max-height: 132px;
    width: auto;
  }
`;

export const PartsIntroContent = styled.div`
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;

  p {
    font-size: 1em;
    color: black;
  }
`;

export const WelcomeContainer = styled.div`
  padding: 0 0px 0px 0px;

  ${media.md`
    padding: 0 30px 30px 30px;
  `};

  .description {
    padding-top: 20px;
    p {
      font-weight: 300 !important;
    }
  }
`;

export const PartsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  justify-content: center;

  .parts-heading {
    font-size: 1.25em;
  }

  .parts-description {
    margin-bottom: 2em;
  }
`;

export default PartsIntroWrapper;
