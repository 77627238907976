import styled from 'styled-components';
import media from '../../styles/media';

export const StyledProgressBar = styled.div`
  padding: 15px;
  flex-grow: 1;
  display: flex;
  align-items: center;

  ${media.md`
    padding: 25px;
  `};
`;

export const StyledProgress = styled.div`
  height: 100%;
  width: ${({ progress, total }) => (progress / total) * 100}%;
  border-radius: 7.5px;
  background-color: ${props => props.theme.white};

  transition: width 150ms ease-in-out;
  will-change: width;
`;

export const StyledTrack = styled.div`
  height: 15px;
  width: 100%;
  border-radius: 7.5px;
  margin-right: 60px;
  background: ${props => props.theme[props.background]};
`;

export const StyledProgressInfo = styled.div`
  color: black;

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
  will-change: background-color, color;

  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-15px, -50%);

  height: 40px;
  width: 50px;
  border-radius: 7.5px;

  line-height: 40px;
  text-align: center;
  font-size: 0.75em;
  font-weight: 300;

  ${media.md`
    transform: translate(-25px, -50%);
    font-size: 2rem;
  `};
`;
