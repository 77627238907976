import styled from 'styled-components';

export const AvatarForm = styled.div`
  width: 150px;
  margin: 20px;
  position: relative;

  img {
    width: 150px;
    margin-bottom: -20px;
  }

  label {
    display: block;
    margin: 10px 0;
    margin-top: 0;
  }

  input {
    width: 100%;
    border: 1px solid ${props => props.theme.border};
    font-size: 0.75em;
    padding: 10px;
    font-family: ${props => props.theme.fontFamily};
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

export default AvatarForm;
