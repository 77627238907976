import React, { useState } from 'react';

import Modal, { ModalBody, ModalHeader, ModalFooter } from '../Modal';
import Button from '../Button';
import { AVATARS, CDN } from '../../constants';
import { AvatarContainer, Avatar } from './styles';

type AvatarModalProps = {
  onClose: Function,
  handleSelect: Function,
  isOpen: Boolean,
};

const AvatarModal = ({ onClose, handleSelect, isOpen }: AvatarModalProps) => {
  const [chosenAvatar, setChosenAvatar] = useState(null);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <h6>Welke afbeelding past het best bij de persoon die je wil toevoegen?</h6>
      </ModalHeader>
      <ModalBody>
        <AvatarContainer>
          {AVATARS.map(({ slug, src }) => (
            <Avatar
              role="button"
              key={slug}
              isChosen={slug === chosenAvatar}
              onClick={() => {
                setChosenAvatar(slug);
              }}
            >
              {src.indexOf('http') < 0 ? (
                <img src={`${CDN}assets/avatar/${src}`} alt={slug} />
              ) : (
                <img src={src} alt={slug} />
              )}
            </Avatar>
          ))}
        </AvatarContainer>
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            handleSelect(chosenAvatar);
            setChosenAvatar(null);
          }}
          disabled={!chosenAvatar}
        >
          + Toevoegen
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AvatarModal;
