import { createGlobalStyle } from 'styled-components';
import media from './media';

export default createGlobalStyle`
    @font-face {
        font-family: 'symbols_vad3';

        src: url('${props => props.theme.cdn}assets/fonts/symbols_vad3.eot');
        src: url('${props => props.theme.cdn}assets/fonts/symbols_vad3.eot') format('embedded-opentype'),
            url('${props => props.theme.cdn}assets/fonts/symbols_vad3.ttf') format('truetype'),
            url('${props => props.theme.cdn}assets/fonts/symbols_vad3.woff') format('woff'),
            url('${props => props.theme.cdn}assets/fonts/symbols_vad3.svg') format('svg');

        font-weight: normal;
        font-style: normal;
    }

    #bol-zelfhulptool3 *, #bol-zelfhulptool3 * * > * {
      font-size: 16px;
    }

    #bol-zelfhulptool3 {
      font-weight: 400;
    }

    #bol-zelfhulptool3 strong {
        font-weight: bold;
    }

    #bol-zelfhulptool3 h1 {
        text-decoration: none;
    }

    .embed-container:before
    {
        padding-bottom: 0;
    }

    .section.section--content.section--default
    {
        background-color: #fff;
        background-image: url('${props => props.theme.cdn}assets/background@2x.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }


    #bol-zelfhulptool3 {
        /* reset */
        div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        main, menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
        }
        /* end reset */

        font-family: ${props => props.theme.fontFamily};
        font-size: ${props => props.theme.fontSizeBase};
        line-height: ${props => props.theme.lineHeightBase};
        color: ${props => props.theme.color};
        overflow-x:hidden;
        overflow-y:visible;

        *, ::after, ::before {
            box-sizing: border-box;
        }

        * {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        [class^="icon-"], [class*=" icon-"] {
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'symbols_vad3' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .icon-icon-child:before {
            content: "\\e900";
        }
        .icon-icon-children:before {
            content: "\\e901";
        }
        .icon-icon-emotions:before {
            content: "\\e902";
        }
        .icon-icon-finale:before {
            content: "\\e903";
        }
        .icon-icon-heart:before {
            content: "\\e904";
        }
        .icon-icon-kids:before {
            content: "\\e905";
        }
        .icon-icon-lamp:before {
            content: "\\e906";
        }
        .icon-icon-network:before {
            content: "\\e907";
        }
        .icon-icon-eye:before {
            content: "\\e908";
        }
        .icon-icon-plus:before {
            content: "\\e909";
        }
        .icon-icon-speechbubble:before {
            content: "\\e90a";
        }
        .icon-icon-next:before {
            content: "\\e90b";
        }
        .icon-icon-previous:before {
            content: "\\e90c";
        }
        .icon-icon-question:before {
            content: "\\e90d";
        }
        .icon-icon-care:before {
            content: "\\e90e";
        }

        .section.section--content.section--default {
            background: url('${props => props.theme.cdn}assets/bg.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }

        h1, h2, h3, h4, h5, h6 {
            color: ${props => props.theme.headingsColor};
            font-weight: ${props => props.theme.headingsFontWeight};
            margin-bottom: .5em;
        }

        h1 {
            font-size: 2.5rem;
            line-height: 3rem;
            font-weight: 800;
            ${media.md`
            font-size: 2.5em;
             `};
        }

        h2 {
            font-size: 1.5em;
            line-height: 2em;
        }

        p {
            margin-bottom: 1em;
        }

        a {
            text-decoration-line:  underline ;
            text-decoration-color: ${props => props.theme.primary};
            text-decoration-style: solid ;
            text-decoration-thickness: 2px ;
          
            -webkit-text-decoration-line:  underline ;
            -webkit-text-decoration-color: ${props => props.theme.primary};
            -webkit-text-decoration-style: solid ;
            -webkit-text-decoration-thickness: 2px ;

            text-decoration: none;

            color: black;
            line-height: 26px;
        }

        strong {
            font-weight: 700;
        }

        button:focus {
            outline: none;
        }

        input[type=range] {
            appearance: none;
            width: 100%;
            height: 24px;
            background: transparent;
        }

        input[type=range]:focus {
        outline: none;
        }

        input[type=range]::-webkit-slider-runnable-track
        {
            width: 100%;
            height: 14px;
            cursor: pointer;
            background-color: #ECEFF0;
            border-radius: 7px;
        }

        input[type=range]::-moz-range-track
        {
            cursor: pointer;
            width: 100%;
            height: 14px;
            background-color: #ECEFF0;
            border-radius: 7px;
        }

        input[type=range]::-ms-track
        {
            cursor: pointer;
            width: 100%;
            height: 14px;
            background-color: #ECEFF0;
            border-radius: 7px;
        }

        input[type=range]::-webkit-slider-thumb {
            appearance: none;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: ${props => (props.color ? props.color : '#ECEFF0')};
            box-shadow: inset 0 0 0 10px ${props => props.theme.primary};
            transform: translateY(-7px);
        }

        input[type=range]::-moz-range-thumb {
            appearance: none;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: ${props => (props.color ? props.color : '#ECEFF0')};
            box-shadow: inset 0 0 0 10px ${props => props.theme.primary};
            transform: translateY(-7px);
        }

        input[type=range]::-ms-thumb {
            appearance: none;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: ${props => (props.color ? props.color : '#ECEFF0')};
            box-shadow: inset 0 0 0 10px ${props => props.theme.primary};
            transform: translateY(0);
        }

        form {
            .form-control {
                height: calc(2em + .75em + 2px);
                padding: .375em 1em;
                font-size: 1em;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid ${props => props.theme.primary};
                border-radius: 0;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
        }

        .form-inline {
            display: flex;
            align-items: center;

            .form-control + button {
                margin-left: 1em;
            }
        }

        .alert {
            padding: 1.25em 1.875em;

            p {
                margin:0;
                font-weight: 600;
                font-size: 0.875em !important;
                line-height:1.375em;
                font-family: ${props => props.theme.fontFamily};
            }

            strong {
                font-weight: 600;
            }

            .alert-italic {
                font-style: italic;
            }

            i {
                margin-right:10px;
            }

            .alert-link-wrapper {
                margin-top:10px;
                display:inline-block;
                font-size: 0.875em!important;
                width: 100%;

                a {
                    margin-left:5px;
                    border-bottom: solid ${props => props.theme.primary} 2px
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .alert.alert-info {
            background-color: #f7f7f7;
            color: black;

            a {
                color: black !important;
            }
        }

        .question-heading {
        text-align: center;
        margin-bottom: 0.5rem;
        font-size: 1.125em;
        line-height: 1.2;
        ${media.md`
        font-size: 1.875em;
        line-height: 2em
        `};
        }

        .question-avatar {
        width: 100%;
        text-align:center;

        img {
            width: 100%;
            max-width: 150px;
        }
        }

        .question-description {
        text-align: center;
        margin-bottom: 2em;

        ${media.md`
            margin-bottom: 3em;
        `};
        }

        .red-flag {
            font-style: italic;
            color: ${props => props.theme.headingsColor}
        }

        .close {
            width: 15px;
            height: 15px;
            position: relative;
            cursor: pointer;

            &:before,
            &:after {
            position: absolute;
            left: 50%;
            top: 50%;

            content: '';
            height: 20px;
            width: 2px;
            background-color: ${props => props.theme.border};
            }
            &:before {
            transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        .center {
            text-align: center;
        }
    }
}
`;
