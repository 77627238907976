import styled from 'styled-components';

export const NewWordModalWrapper = styled.div`
  .modal-content {
    max-width: 30vw !important;
  }
`;

export const Form = styled.form`
  .form-group {
    width: 100%;
    position: relative;

    input {
      display: block;
      width: 100%;
      padding: 10px 55px 10px 10px;
      border: 1px solid ${props => props.theme.border};
      outline: none;
      font-size: 1em;
    }

    .form-text {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      color: ${props => props.theme.gray};
    }
  }

  button {
    float: right;
    margin-top: 20px;
  }
`;

export default NewWordModalWrapper;
