import React from 'react';

import { Statement, StatementSubtitle } from '../../Slider/styles';
import { ConclusionContainer, ConclusionWrapper } from './styles';

type ConclusionProps = {
  title: String,
  subTitle: String,
  description: String,
}

const Conclusion = ({
  title, subTitle, description,
}: ConclusionProps) => (
  <ConclusionContainer>
    <ConclusionWrapper>
      <Statement>{title}</Statement>
      <StatementSubtitle dangerouslySetInnerHTML={{ __html: subTitle }} />
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </ConclusionWrapper>
  </ConclusionContainer>
);

export default Conclusion;
