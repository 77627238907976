import styled, { keyframes } from 'styled-components';
import media from '../../../styles/media';

// Create the keyframes
const slideIn = keyframes`
  from {
    transform: translate(0, -20px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

export const StyledForm = styled.form`
  ${media.md`
    margin-top: 25px;
  `};
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  display: block;

  span {
    margin-left: 5px;
  }
`;

export const StyledResult = styled.div`
  transition: all 250ms ease-in-out;
  margin-top: 20px;

  animation: ${slideIn} 500ms ease-in-out;

  strong {
    color: ${props => props.theme.partorange};
  }

  .alert {
    margin-bottom: 20px;
  }
`;

export const StyledQuote = styled.blockquote`
  font-style: italic;
  border-left: 0.2em solid ${props => props.theme.primary};
  padding-left: 15px;
  font-weight: bold;
`;
