import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const NetworkRadarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 60vh;

  .radar-circle {
    margin: 30px 0;
    ${media.md`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        margin: 0;
    `};
  }
`;

export const RadarPerson = styled.div`
  margin: 10px 10px 10px 0;
  width: 100%;

  ${media.md`
    position: absolute;
    margin: 0;
    width: 70px;
  `};

  div {
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    display: flex;
    align-items: center;

    ${media.md`
        display:block;
    `};

    img {
      width: 100%;
      max-width: 100px;
      display: inline-block;
    }

    span {
      display: inline-block;
      font-size: 0.75em;

      width: auto;

      ${media.md`
        margin: 0;
        width: 100%;
        transform: translate(0, -10px);
    `};
    }
  }
`;

export const RadarLabel = styled.div`
  padding: 0px 5px;
  font-size: 0.75em;
  color: ${props => props.theme.border};
  box-shadow: 0 0 25px ${props => props.theme.gray};
  background: white;

  ${media.md`
    position: absolute;
    max-width: 130px;
    transform: translate(50%, -50%);
    right: 0;
    top: 50%;
  `};

  ${props => props.left
    && css`
      ${media.md`
            right: auto;
            left: 0;
            transform: translate(-50%, -50%);
        `};
    `};
`;

export const RadarFamily = styled.div`
  z-index: 2;
  width: 100%;

  ${media.md`
    width: 33%;
    height: 33%;
  `};
`;

export const RadarFriends = styled.div`
  z-index: 3;
  width: 100%;

  ${media.md`
    width: 60%;
    height: 66%;
  `};
`;

export const RadarOther = styled.div`
  z-index: 4;
  width: 100%;

  ${media.md`
    height: 100%;
  `};
`;

export default NetworkRadarContainer;
