import React, { useEffect } from 'react';
import { signal } from 'cerebral/tags';
import { connect } from '@cerebral/react';

import {
  SplitWrapper,
  QuoteIntro,
  IntroContent,
  IntroContentWrapper,
  ButtonWrapper,
} from './styles';
import { Splitview, Split } from '../../components/Split';
import Page from '../../components/Page';
import Quote from '../../components/Quote';
import Button from '../../components/Button';
import { CDN } from '../../constants';

type PartIntroProps = {
  part: String,
  amount: String,
  color: String,
  slug: String,
  title: String,
  description: String,
  quote: String,
  sections: Array,
  subPageRouted: Function,
  eventTracked: Function,
  homeRouted: Function,
  skippable: Boolean,
  partFinished: Function,
};

const PartIntro = ({
  part,
  amount,
  color,
  title,
  slug,
  description,
  quote,
  sections,
  subPageRouted,
  eventTracked,
  homeRouted,
  skippable,
  partFinished,
}: PartIntroProps) => {
  useEffect(() => {
    eventTracked({
      eventAction: 'part_started',
      eventProperties: {
        event_label: slug,
      },
    });
  });

  return (
    <Page>
      <Splitview>
        <SplitWrapper padding={0} left background={`partlight${color}`}>
          <QuoteIntro>
            <div className="QuoteIntroImage">
              <img src={`${CDN}assets/quotes/quote_chapter${part}@2x.png`} alt="" className="quote-img" />
            </div>
            <Quote quote={quote} />
          </QuoteIntro>
        </SplitWrapper>
        <Split right>
          <IntroContent color={color}>
            <IntroContentWrapper>
              <h6 className="sub-heading">
                Deel
                {' '}
                {part}
                {' '}
                van
                {' '}
                {amount}
              </h6>
              <h1 className="heading">{title}</h1>
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </IntroContentWrapper>
            <ButtonWrapper>
              {skippable && (
                <Button
                  outline
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    partFinished({ part: slug });
                    homeRouted({});
                  }}
                >
                  Niet van toepassing
                </Button>
              )}
              <Button
                direction="next"
                light
                color={color}
                onClick={() => subPageRouted({
                  page: slug,
                  subPage: sections[0].slug,
                })
                }
              >
                Start
              </Button>
            </ButtonWrapper>
          </IntroContent>
        </Split>
      </Splitview>
    </Page>
  );
};

const stateSignals = {
  subPageRouted: signal`app.subPageRouted`,
  eventTracked: signal`app.eventTracked`,
  homeRouted: signal`app.homeRouted`,
  partFinished: signal`app.partFinished`,
};

export default connect(
  stateSignals,
  PartIntro,
);
