import { set } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import trackEvent from '../actions/trackEvent';
import markPartFinished from '../actions/markPartFinished';
import trackFullCompletionEvent from '../actions/trackFullCompletionEvent';

export default [
  markPartFinished,
  set(props`eventAction`, 'part_completed'),
  set(props`eventProperties.event_label`, props`part`),
  trackEvent,
  trackFullCompletionEvent,
];
