import React from 'react';
import { connect } from '@cerebral/react';
import { signal } from 'cerebral/tags';

import Page from '../components/Page';
import Button from '../components/Button';

type notFoundProps = {
  homeRouted: Function,
};

const NotFound = ({ homeRouted }: notFoundProps) => (
  <Page>
    <div
      style={{
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <p className="center">404: Deze pagina bestaat niet</p>
      <Button style={{ alignSelf: 'center' }} onClick={() => homeRouted()}>
        Terug naar de start pagina
      </Button>
    </div>
  </Page>
);

const stateSignals = {
  homeRouted: signal`app.homeRouted`,
};

export default connect(
  stateSignals,
  NotFound,
);
