import { set, when } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';
import submitAnswers from '../actions/submitAnswers';

export default [
  set(state`app.answers.${props`part`}.${props`section`}`, props`answer`),
  when(props`isLast`),
  {
    true: [
      submitAnswers,
    ],
    false: [],
  },
];
