import { set } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';

import resetScroll from '../actions/resetScroll';
import trackPageView from '../actions/trackPageView';

export default [
  set(state`app.currentPage`, props`page`),
  set(state`app.currentSubPage`, props`subPage`),
  resetScroll,
  trackPageView,
];
