import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const WordContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Word = styled.div`
  margin: 1em;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  cursor: ${props => (props.isDisabled ? 'initial' : 'pointer')};
  opacity: ${props => (props.isDisabled && !props.isActive ? '0.5' : '1')};

  color: ${props => (props.theme.color)};

  .word {
    text-decoration-line:  underline ;
    text-decoration-color: ${props => (props.isActive ? props.theme[`part${props.color}`] : props.theme.white)};
    text-decoration-style: solid ;
    text-decoration-thickness: 2px ;
  
    -webkit-text-decoration-line:  underline ;
    -webkit-text-decoration-color: ${props => (props.isActive ? props.theme[`part${props.color}`] : props.theme.white)};
    -webkit-text-decoration-style: solid ;
    -webkit-text-decoration-thickness: 2px ;
  }

  .emoji{
    font-size:1.5em;
    transform: translate(0,3px);
    display:inline-block;
    line-height: 1em;
  }

  ${media.md`
  font-size: 2rem;
  `};

  ${props => props.selectionView
    && css`
      color: ${propSelection => (propSelection.theme.color)};
      text-decoration-line:  underline ;
      text-decoration-color: ${propSelection => (propSelection.isActive ? propSelection.theme[`part${props.color}`] : propSelection.theme.white)};
      text-decoration-style: solid ;
      text-decoration-thickness: 2px ;
      
      -webkit-text-decoration-line:  underline ;
      -webkit-text-decoration-color: ${propSelection => (propSelection.isActive ? propSelection.theme[`part${props.color}`] : propSelection.theme.white)};
      -webkit-text-decoration-style: solid ;
      -webkit-text-decoration-thickness: 2px ;
    `};
`;

export default WordContainer;
