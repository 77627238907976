import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  Root, Dialog, ModalBody, ModalFooter, ModalHeader, CloseButtonContainer,
} from './styles';

type ModalProps = {
  onClose: void,
  children: any,
};

let modalRoot = document.getElementById('bol-zelfhulptool3');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  document.body.appendChild(modalRoot);
}

class Modal extends Component<ModalProps> {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.classList.add('modal-open');
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
    modalRoot.removeChild(this.el);
  }

  render() {
    const { onClose, children } = this.props;

    return ReactDOM.createPortal(
      <Root onClick={onClose}>
        <Dialog
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="modal-content"
        >
          <CloseButtonContainer>
            <div className="close" onClick={onClose} />
          </CloseButtonContainer>
          {children}
        </Dialog>
      </Root>,
      this.el,
    );
  }
}

export { ModalHeader, ModalFooter, ModalBody };
export default Modal;
