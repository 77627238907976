import styled from 'styled-components';

export const Avatar = styled.div`
  width: 150px;
  height: 170px;
  margin: 10px;

  img {
    width: 100%;
    height: auto;
  }

  span {
    width: 100%;
    text-align: center;
  }
`;

export default Avatar;
