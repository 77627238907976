import styled from 'styled-components';
import media from '../../../styles/media';

export const StyledDivWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  ${media.md`
    border: 2px solid ${props => props.theme.lightGray};
  `};
`;

export const StyledRow = styled.div`
  ${props => props.background
    && `background: ${props.theme.lightGray}`};

  ${media.md`
    display:flex;
  `};
`;

export const StyledChildDiv = styled.div`
  flex: 1;

  ${media.md`
    ${props => props.showBorder && props.header
      && `border-left: dashed 2px ${props.theme.white}; border-right: dashed 2px ${props.theme.white};`};

    ${props => props.showBorder && props.body
      && `border-left: solid 2px ${props.theme.lightGray}; border-right: solid 2px ${props.theme.lightGray};`};
  `};

  ${props => props.order
    && `order ${props.order}`};
`;

export const StyledChildDivHeader = styled.div`
  padding: 10px 15px;
  display: none;

  ${media.md`
    display:inline-block;
  `};
`;

export const StyledMobileTitle = styled.p`
  margin-bottom: 0;
  margin-top: 20px;
  padding: 5px 10px;
  background: ${props => props.theme.lightGray};

  ${media.md`
    display:none;
  `};
`;

export const StyledList = styled.ul`
  list-style-type: disc;
  padding: 10px 15px 10px 30px;
  border: solid 2px ${props => props.theme.lightGray};

  ${media.md`
    border: none;
  `};
`;

export default StyledDivWrapper;
