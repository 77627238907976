import styled from 'styled-components';

export const StyledPoster = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const StyledPreview = styled.div`
  background-color: #fff;
  box-shadow: 0 11px 11px -7px rgba(0, 0, 0, 0.36);
  width: 350px;
  height: 495px;
  margin-bottom: 1em;
  position: relative;
`;
