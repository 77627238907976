import React, { useState } from 'react';

import WordContainer, { Word } from './styles';
import NewWordModal from '../../NewWordModal';
import { Split } from '../../Split';

type WordpickerProps = {
  title: String,
  subTitle: String,
  selectionView: String,
  words: Array,
  suffixes: Array,
  redFlags: Object,
  answer: Array,
  setAnswer: Function,
  isModalOpen: Boolean,
  toggleModal: Function,
  max: Number,
  setTip: Function,
  setRedFlag: Function,
  modalContent: String,
  color: String,
};

const WordPicker = ({
  title,
  subTitle,
  selectionView,
  words: initialWords,
  suffixes = [],
  redFlags = {},
  answer = [],
  setAnswer,
  isModalOpen,
  toggleModal,
  max = null,
  setTip,
  setRedFlag,
  modalContent,
  color,
}: WordpickerProps) => {
  const [words, setWords] = useState([...new Set(initialWords.concat(answer))]);

  return (
    <Split width={100}>
      {title && <h2 className="question-heading">{title}</h2>}
      {subTitle && (
        <div className="question-description">
          <p dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>
      )}
      <WordContainer>
        {words.map((word, wordIndex) => (
          <Word
            key={word}
            color={color}
            isDisabled={max !== null && max !== 1 && answer.length >= max}
            isActive={answer.includes(word)}
            selectionView={selectionView}
            onClick={() => {
              const redFlag = redFlags[word];
              if (redFlag) {
                setRedFlag(redFlag);
              }

              if (answer.includes(word)) {
                const index = answer.findIndex(w => w === word);
                const newValue = [...answer.slice(0, index), ...answer.slice(index + 1)];
                setAnswer(newValue, newValue.length > 0);
                if (setTip) setTip(newValue);
              } else if (max === 1) {
                setAnswer([word], true);
                if (setTip) setTip([word]);
              } else if (max === null || answer.length < max) {
                setAnswer([...answer, word], true);
                if (setTip) setTip([...answer, word]);
              }
            }}
          >
            <span className="word" dangerouslySetInnerHTML={{ __html: word }} />
            {suffixes[wordIndex] && <span dangerouslySetInnerHTML={{ __html: ` ${suffixes[wordIndex]}` }} />}
          </Word>
        ))}
      </WordContainer>
      <NewWordModal
        onClose={toggleModal}
        onAddWord={(word) => {
          setWords([...words, word]);
          if (max === null || answer.length < max) {
            setAnswer([...answer, word], true);
          }
          toggleModal();
        }}
        content={modalContent}
        isOpen={isModalOpen}
      />
    </Split>
  );
};

export default WordPicker;
