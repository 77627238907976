import styled from 'styled-components';
import media from '../../../styles/media';
import Button from '../../Button';
import Footer from '../../Footer';

export const WordExplainerWrapper = styled.div`
  text-align: center;
  height: 100%;
`;

export const WordContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const StyledFooter = styled(Footer)`
  && {
    margin-top: 30px;
    ${media.md`
      margin-top: 0px;
        position: absolute;
        bottom: 0;
        width: 100%;
    `};
  }
`;

export const StyledLabel = styled.label`
  font-size: 1em;
  font-weight: 800;
  color: ${props => props.theme.primaryDark};

  .emoji{
    font-size:1.5em;
    transform: translate(0,3px);
    display:inline-block;
    line-height: 1em;
  }
`;

export const StyledWordExplainer = styled.div`
  /* display: flex;
  flex-direction: column;

  width: 33.33%;
  min-width: 320px;
  padding: ${props => props.theme.btnPaddingY} ${props => props.theme.btnPaddingX}; */
  width: 100%;
`;

export const ChosenWordExplanation = styled.div`
  margin-bottom: 30px;

  label,
  input {
    display: inline-block;
    width: 100%;
  }

  label {
    font-weight: 600;
  }

  input {
    font-size: 1em;
    padding: 10px 15px;
    border: 1px solid ${props => props.theme.border};
    margin-top: 10px;
    font-family: ${props => props.theme.fontFamily};
    max-width: 700px;
  }
`;

export const ChosenWordAddButton = styled(Button)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
`;

export default WordExplainerWrapper;
