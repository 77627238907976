import React, { useState } from 'react';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Loader from '../Loader';
import Button from '../Button';
import { APP_URL } from '../../constants';
import StyledSaveProgress from './styles';

const validate = (email) => {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(email.toLowerCase());
};

type SaveProgressProps = {
  emailLoading: Boolean,
  error: Object,
  id: String,
  emailId: Function,
  answers: Object,
  eventTracked: Function,
};

const SaveProgress = ({
  emailLoading,
  error,
  id,
  emailId,
  answers,
  eventTracked,
}: SaveProgressProps) => {
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [email, setEmail] = useState('');

  if (Object.keys(answers).length === 0) {
    return null;
  }

  const copied = () => {
    eventTracked({
      eventAction: 'progress_saved_link',
    });
    setIsCopied(true);
  };

  const handleMailChange = (e) => {
    const valid = validate(e.target.value);
    setEmail(e.target.value);
    setIsValid(valid);
  };

  const sendMail = (e) => {
    e.preventDefault();

    if (validate(email)) {
      emailId({ email });
      eventTracked({
        eventAction: 'progress_saved_email',
      });
    }

    setIsEmailSend(true);
  };

  return (
    <StyledSaveProgress>
      <div>
        <h6>Stuur jezelf een mailtje om alles later&nbsp;verder&nbsp;af&nbsp;te&nbsp;werken:</h6>
        {error && (
          <p>Er ging iets mis bij het verzenden van de email, probeer het later nog eens opnieuw</p>
        )}
        <CopyToClipboard onCopy={copied} text={`${APP_URL}?id=${id}`}>
          <Button style={{ display: 'inline-block', fontSize: '2rem' }} bare>
            Of kopieer deze unieke link, en sla hem op op een veilige plek.
          </Button>
        </CopyToClipboard>
        {isCopied && <p>Gekopieerd!</p>}
      </div>
      {emailLoading && <Loader />}
      {!emailLoading && (
        <React.Fragment>
          <form>
            <input
              type="email"
              className="form-control mr-2"
              id="inlineFormInput"
              onChange={handleMailChange}
              placeholder="naam@email.be"
              value={email}
            />
            <Button primary disabled={!isValid} onClick={sendMail}>
              {isEmailSend ? 'Verstuur opnieuw' : 'Verstuur'}
            </Button>
          </form>
        </React.Fragment>
      )}

    </StyledSaveProgress>
  );
};

const stateSignal = {
  eventTracked: signal`app.eventTracked`,
  emailId: signal`app.mailId`,
  emailLoading: state`app.mail.loading`,
  error: state`app.mail.error`,
  id: state`app.id`,
  answers: state`app.answers`,
};

export default connect(
  stateSignal,
  SaveProgress,
);
