import React from 'react';

import { Splitview, Split } from '../../Split';

import { Statement, StatementSubtitle } from '../../Slider/styles';
// import { StyledColumns, StyledColumn, StyledColumnHeader, StyledList } from './styles';
import { StyledDivWrapper, StyledChildDiv, StyledChildDivHeader, StyledList, StyledRow, StyledMobileTitle } from './styles';

type HelpOverviewProps = {
  title: String,
  subTitle: String,
  description: String,
  dependData: Object,
}

const HelpOverview = ({ title, subTitle, description, dependData }: HelpOverviewProps) => (
  <Splitview>
    <Split width="100">
      <Statement>{title}</Statement>
      <StatementSubtitle>{subTitle}</StatementSubtitle>
      <div dangerouslySetInnerHTML={{ __html: description }} />

      <StyledDivWrapper>
        <StyledRow background>
          <StyledChildDiv>
            <StyledChildDivHeader>Wat heb ik allemaal al gedaan of geprobeerd om mijn kind te helpen?</StyledChildDivHeader>
          </StyledChildDiv>
          <StyledChildDiv showBorder header>
            <StyledChildDivHeader>Wat overweeg ik nog te doen om mijn kind te helpen?</StyledChildDivHeader>
          </StyledChildDiv>
          <StyledChildDiv>
            <StyledChildDivHeader>Wat wil ik voortaan anders doen?</StyledChildDivHeader>
          </StyledChildDiv>
        </StyledRow>
        <StyledRow>
          <StyledChildDiv>
            <StyledMobileTitle>Wat heb ik allemaal al gedaan of geprobeerd om mijn kind te helpen?</StyledMobileTitle>
            <StyledList>
              {dependData['geprobeerd-kind-helpen'].map(word => (
                <li key={word}>{word}</li>
              ))}
            </StyledList>
          </StyledChildDiv>
          <StyledChildDiv showBorder body>
            <StyledMobileTitle>Wat overweeg ik nog te doen om mijn kind te helpen?</StyledMobileTitle>
            <StyledList>
              {dependData['overwegen-kind-helpen'].map(word => (
                <li key={word}>{word}</li>
              ))}
            </StyledList>
          </StyledChildDiv>
          <StyledChildDiv>
            <StyledMobileTitle>Wat wil ik voortaan anders doen?</StyledMobileTitle>
            <StyledList>
              {dependData['anders-doen'].map(word => (
                <li key={word}>{word}</li>
              ))}
            </StyledList>
          </StyledChildDiv>
        </StyledRow>
      </StyledDivWrapper>
    </Split>
  </Splitview>
);

export default HelpOverview;
