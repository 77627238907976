/* eslint-disable no-underscore-dangle */
export default async function ({ state }) {
  const parts = state.get('app.data.parts');

  if (parts.filter(({ isFinished }) => isFinished).length >= 6) {
    if (window.gtag) {
      window.gtag('event', 'all_parts_completed', {});
    }
    if (window._paq) {
      window._paq.push(['trackEvent', 'GRIP ouder van', 'all_parts_completed']);
    }
  }
}
