import styled from 'styled-components';

export const AddButton = styled.button`
  height: 110px;
  max-width: 300px;
  width: 100%;
  border: 2px dashed ${props => props.theme.color};
  margin: 15px;
  font-size: 0.875em;

  color: ${props => props.theme.color};
  background-color: #fff;
  text-transform: none;
  border-radius: 0;
  transition: padding 250ms ease-in-out;

  i {
    font-size: 1em;
    margin-right: 10px;
  }
  span {
    text-decoration: underline;
  }

  &.large {
    height: 110px;
    width: 100%;
    max-width: 300px;
    margin-top: 35px;
    display: inline-block;

    i {
      font-size: 1em;
      margin-right: 10px;
    }
  }
`;

export const AvatarContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  /* margin: 0.5em; */

  justify-content: center;
`;
