import { set } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';
import mailId from '../actions/mailId';

export default [
  set(state`app.mail.loading`, true),
  set(state`app.mail.error`, null),
  mailId,
  {
    success: [],
    error: [set(state`app.mail.error`, props`error`)],
  },
  set(state`app.mail.loading`, false),
];
