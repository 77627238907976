const API_URLS = {
  local: '//vad-zelfhulptool.docker/api/v1',
  staging: '//039005-5f03-03a3-0001.staging.d.bitsof.love/api/v1',
  develop: '//039005-5f03-03a3-0001.staging.d.bitsof.love/api/v1',
  master: '//039005-5f03-03a3-0001.production.web1.d.bitsof.love/api/v1',
};

const APP_URLS = {
  master: 'https://www.druglijn.be/grip/ouders-van#/',
  staging: 'https://039008-d6d4-d6ce-0001.staging.s.bitsof.love/#/',
  develop: 'https://039008-d6d4-d6ce-0001.develop.s.bitsof.love/#/',
  local: 'http://localhost:39007/#/',
};

const CDN_URLS = {
  master: '//d16vsiaugdxypr.cloudfront.net/',
  staging: '//039008-d6d4-d6ce-0001.staging.s.bitsof.love/', // '//s3.eu-central-1.amazonaws.com/bitsoflove-staging/039008-d6d4-d6ce-0001/',
  develop: '//039008-d6d4-d6ce-0001.develop.s.bitsof.love/', // '//s3.eu-central-1.amazonaws.com/bitsoflove-develop/039008-d6d4-d6ce-0001/',
};

export const APP = 'zelfhulptool3';
export const API_URL = API_URLS[process.env.REACT_APP_GIT_BRANCH || 'local'];
export const APP_URL = APP_URLS[process.env.REACT_APP_GIT_BRANCH || 'local'];
export const CDN = CDN_URLS[process.env.REACT_APP_GIT_BRANCH || 'local'] || '';
export const IS_PRODUCTION = process.env.REACT_APP_GIT_BRANCH === 'master' || process.env.NODE_ENV === 'master';

export const SLIDER_TICKS = [
  { tickValue: -2, tickLabel: 'Helemaal oneens' },
  { tickValue: -1, tickLabel: 'Oneens' },
  { tickValue: 0, tickLabel: 'Neutraal' },
  { tickValue: 1, tickLabel: 'Eens' },
  { tickValue: 2, tickLabel: 'Helemaal eens' },
];

export const AVATARS = [
  {
    slug: 'avatar1',
    src: 'avatar1@2x.png',
  },
  {
    slug: 'avatar2',
    src: 'avatar2@2x.png',
  },
  {
    slug: 'avatar3',
    src: 'avatar3@2x.png',
  },
  {
    slug: 'avatar4',
    src: 'avatar4@2x.png',
  },
  {
    slug: 'avatar5',
    src: 'avatar5@2x.png',
  },
  {
    slug: 'avatar6',
    src: 'avatar6@2x.png',
  },
  {
    slug: 'avatar7',
    src: 'avatar7@2x.png',
  },
  {
    slug: 'avatar8',
    src: 'avatar8@2x.png',
  },
  {
    slug: 'avatar9',
    src: 'avatar9@2x.png',
  },
  {
    slug: 'avatar10',
    src: 'avatar10@2x.png',
  },
  {
    slug: 'avatar11',
    src: 'avatar11@2x.png',
  },
  {
    slug: 'avatar12',
    src: 'avatar12@2x.png',
  },
];

export const findAvatar = s => AVATARS.find(({ slug }) => s === slug) || {};
