export default async function ({ state, api }) {
  const { id, answers } = state.get('app');

  try {
    await api.put(`/state/${id}`, {
      data: JSON.stringify(answers),
    });
  } catch (error) {
    console.error(error);
  }
}
