import styled from 'styled-components';

export const StyledClickableLink = styled.span`
  color: black;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;

  text-decoration-line:  underline ;
  text-decoration-color: ${props => props.theme.primary};
  text-decoration-style: solid ;
  text-decoration-thickness: 1px ;

  -webkit-text-decoration-line:  underline ;
  -webkit-text-decoration-color: ${props => props.theme.primary};
  -webkit-text-decoration-style: solid ;
  -webkit-text-decoration-thickness: 1px ;
  
  margin: 14px 20px
`;

export default StyledClickableLink;
