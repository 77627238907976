import React, { useState, useEffect } from 'react';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';

import screens from '../components/Screens';
import Page from '../components/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Screen from '../components/Screens/styles';
import Button from '../components/Button';
import getDependData from '../utils/getDependData';
import ClickableLink from '../components/ClickableLink';

type PartSectionProps = {
  firstTime: Boolean,
  part: Object,
  parts: Array,
  section: Object,
  answerUnset: Function,
  answerSet: Function,
  answers: Object,
  subPageRouted: Function,
  pageRouted: Function,
  triggerFirstTime: Function,
  homeRouted: Function,
  answerSubmitted: Function,
  partFinished: Function,
  setTip: Function,
  setRedFlag: Function,
  redFlags: Array,
};

const PartSection = ({
  firstTime,
  part,
  section,
  answerUnset,
  answerSet,
  answers,
  subPageRouted,
  pageRouted,
  triggerFirstTime,
  homeRouted,
  answerSubmitted,
  partFinished,
  setTip,
  setRedFlag,
  redFlags = [],
}: PartSectionProps) => {
  const [isValid, setIsValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sections } = part;
  const {
    depend, hasValidation, defaultValue, button, controlledFooter, isSkipable,
  } = section;

  useEffect(() => {
    triggerFirstTime();
  }, [triggerFirstTime]);

  // get current section and part id
  const currentSectionId = sections.findIndex(s => s.slug === section.slug);
  // const currentPartId = parts.findIndex(p => p.slug === part.slug);

  if (!answers[part.slug] && currentSectionId !== 0 && firstTime) {
    pageRouted({
      page: part.slug,
    });
    return null;
  }

  let answer;
  if (answers[part.slug] && typeof answers[part.slug][section.slug] !== 'undefined') {
    answer = answers[part.slug][section.slug];
  }

  const dependData = getDependData({
    depend,
    answers,
    part,
  });

  if (undefined === dependData && depend) {
    subPageRouted({
      page: part.slug,
      subPage: depend,
    });
    return null;
  }

  // get next, previous and current sections.
  const next = sections[currentSectionId + 1];
  const previous = sections[currentSectionId - 1];
  const current = section;

  // get questionComponent
  const ScreenComponent = screens[current.question];

  const nextButton = currentSectionId + 1 === sections.length ? (
    <Button
      direction="next"
      light
      color={part.color}
      disabled={!isValid && hasValidation}
      onClick={() => {
        if (answer === undefined && defaultValue !== undefined) {
          answerSet({
            part: part.slug,
            section: section.slug,
            answer: defaultValue,
          });
        }

        answerSubmitted();
        partFinished({ part: part.slug });
        homeRouted();
      }}
    >
      Klaar
    </Button>
  ) : (
    <Button
      direction="next"
      disabled={!isValid && hasValidation}
      light
      color={part.color}
      onClick={() => {
        if (answer === undefined && defaultValue !== undefined) {
          answerSet({
            part: part.slug,
            section: section.slug,
            answer: defaultValue,
          });
        }

        setIsValid(answers[part.slug] && answers[part.slug][next.slug] !== undefined);
        subPageRouted({
          page: part.slug,
          subPage: next.slug,
        });
      }}
    >
        Volgende
    </Button>
  );

  const previousButton = (
    <Button
      direction="previous"
      color={part.color}
      onClick={() => {
        setIsValid(true);
        subPageRouted({
          page: part.slug,
          subPage: previous.slug,
        });
      }}
      light
    >
      Vorige
    </Button>
  );

  const centerButton = button && (
    <Button add bare color={part.color} onClick={() => setIsModalOpen(value => !value)}>
      {button}
    </Button>
  );

  return (
    <Page>
      <Header
        part={part.title}
        partColor={part.color}
        partNumber={part.part}
        progress={currentSectionId + 1}
        total={sections.length}
      />
      <Screen>
        {Screen && (
          <ScreenComponent
            answer={answer}
            answers={answers && answers}
            dependData={dependData && dependData}
            color={part.color}
            setAnswer={(value, valueValid = null) => {
              if (valueValid !== null) {
                setIsValid(valueValid);
              }

              answerSet({
                part: part.slug,
                section: section.slug,
                answer: value,
              });
            }}
            setTip={(tip) => {
              setTip({
                part: part.slug,
                section: section.slug,
                tip,
              });
            }}
            setRedFlag={(redFlag) => {
              setRedFlag({
                part: part.slug,
                redFlag,
              });
            }}
            key={current.slug}
            part={part.slug}
            section={current.slug}
            sectionId={currentSectionId}
            toggleModal={() => setIsModalOpen(value => !value)}
            isModalOpen={isModalOpen}
            nextButton={nextButton}
            centerButton={centerButton}
            previousButton={previous && previousButton}
            redFlag={redFlags[part.slug]}
            {...current}
            {...current.props}
          />
        )}
      </Screen>
      {!controlledFooter && (
        <Footer>
          <div className="footer-center">{centerButton}</div>
          <div className="footer-left">{previous ? previousButton : <div>&nbsp;</div>}</div>
          <div className="footer-right">
            {isSkipable && (
              <ClickableLink
                style={{ marginRight: '10px' }}
                onClick={() => {
                  answerUnset({
                    part: part.slug,
                    section: section.slug,
                  });
                  setIsValid(answers[part.slug] && answers[part.slug][next.slug] !== undefined);
                  subPageRouted({
                    page: part.slug,
                    subPage: typeof isSkipable === 'string' ? isSkipable : next.slug,
                  });
                }}
              >
                Sla dit scherm over
              </ClickableLink>
            )}
            {nextButton}
          </div>
        </Footer>
      )}
    </Page>
  );
};

const stateSignal = {
  answerUnset: signal`app.answerUnset`,
  answerSubmitted: signal`app.answerSubmitted`,
  subPageRouted: signal`app.subPageRouted`,
  pageRouted: signal`app.pageRouted`,
  homeRouted: signal`app.homeRouted`,
  answerSet: signal`app.answerSet`,
  triggerFirstTime: signal`app.triggerFirstTime`,
  partFinished: signal`app.partFinished`,
  firstTime: state`app.firstTime`,
  answers: state`app.answers`,
  parts: state`app.data.parts`,
  setTip: signal`app.setTip`,
  setRedFlag: signal`app.setRedFlag`,
  redFlags: state`app.answers.redFlags`,
};

export default connect(
  stateSignal,
  PartSection,
);
