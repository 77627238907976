import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  to {
    transform: translate(-50%,-50%) rotate(360deg);
  }
`;

export const StyledLoader = styled.div`
  margin: 0 auto;

  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

export const StyledSpinner = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  border: 4px solid ${props => props.theme.primary};
  border-color: ${props => props.theme.primary} transparent transparent transparent;
  border-radius: 100%;

  animation: ${spin} 1.2s infinite;

  /* box-sizing: border-box;
  display: block;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 30px;
  height: 30px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent; */

`;

export default StyledLoader;
