import { set, when } from 'cerebral/operators';
import { state, props } from 'cerebral/tags';

import resetScroll from '../actions/resetScroll';
import trackPageView from '../actions/trackPageView';
import trackEvent from '../actions/trackEvent';

export default [
  set(state`app.currentPage`, 'main'),
  set(state`app.currentSection`, null),
  when(props`id`),
  {
    true: [
      set(state`app.id`, props`id` || null),
      set(props`eventAction`, 'returning_user'),
      trackEvent,
    ],
    false: [],
  },
  resetScroll,
  trackPageView,
];
