/* eslint-disable no-underscore-dangle */
export default function trackGA({ props }) {
  const { eventAction, eventProperties = {} } = props;
  if (window.gtag) {
    window.gtag('event', eventAction, eventProperties); // eslint-disable-line
  }
  if (window._paq) {
    window._paq.push(['trackEvent', 'GRIP ouder van', eventAction, eventProperties.event_label ? eventProperties.event_label : '']);
  }
}
