import { APP } from '../../../../constants';

export default async function ({ path, state, api }) {
  try {
    const response = await api.post(`/state/${APP}`, {});

    if (response.data) {
      const { id } = JSON.parse(response.data);
      state.set('app.id', id);
    }

    return path.success({});
  } catch (error) {
    return path.error({ error });
  }
}
