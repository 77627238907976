import React from 'react';

import { StyledButton } from './styles';
import Icon from '../Icon';

type ButtonProps = {
  children: any,
  direction: string,
  add: any,
  color: String,
};

const Button = ({
  children, direction, add, color, ...props
}: ButtonProps) => (
  <StyledButton {...props} color={color}>
    {add ? <Icon name="plus" /> : ''}
    {direction !== '' && direction === 'previous' ? <Icon name={direction} /> : ''}
    {children}
    {' '}
    {direction !== '' && direction === 'next' ? <Icon name={direction} /> : ''}
  </StyledButton>
);

export default Button;
