import React from 'react';

import { StyledQuote } from './styles';

type QuoteProps = {
  quote: String,
};

const Quote = ({ quote }: QuoteProps) => <StyledQuote>{quote}</StyledQuote>;

export default Quote;
