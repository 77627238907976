export default {
  data: null,
  loading: false,
  currentSection: null,
  currentPage: 'main',
  answers: {},
  notification: '',
  firstTime: true,
  id: null,
};
