import React, { useState } from 'react';

import WordContainer, { Word } from './styles';
import NewWordModal from '../../NewWordModal';
import { Split } from '../../Split';

type WordpickerListProps = {
  title: String,
  subTitle: String,
  suffixes: Array,
  words: Array,
  redFlags: Object,
  answer: Array,
  setAnswer: Function,
  isModalOpen: Boolean,
  toggleModal: Function,
  max: Number,
  setTip: Function,
  setRedFlag: Function,
  modalContent: String,
};

const WordPickerList = ({
  title,
  subTitle,
  words: initialWords,
  suffixes = [],
  redFlags = {},
  answer = [],
  setAnswer,
  isModalOpen,
  toggleModal,
  max = null,
  setTip,
  setRedFlag,
  modalContent,
}: WordpickerListProps) => {
  const [words, setWords] = useState([...new Set(initialWords)]);

  const highlightEmotion = (word) => {
    const splitted = word.split('(');
    const highlighted = `${splitted[0]} <strong>(${splitted[1]}</strong>`;
    return highlighted;
  };

  const getStrippedWordWithoutEmoji = (word) => {
    const splitted = word.split("<span class='emoji'>");
    return splitted[0];
  };

  return (
    <Split width={100}>
      {title && <h2 className="question-heading">{title}</h2>}
      {subTitle && (
        <div className="question-description">
          <p dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>
      )}
      <WordContainer>
        {words.map((word, wordIndex) => {
          const strippedWord = getStrippedWordWithoutEmoji(word);
          return (
            <Word
              key={strippedWord}
              isDisabled={max !== null && max !== 1 && answer.length >= max}
              isActive={answer.includes(strippedWord)}
              onClick={() => {
                const redFlag = redFlags[strippedWord];
                if (redFlag) {
                  setRedFlag(redFlag);
                }

                if (answer.includes(strippedWord)) {
                  const index = answer.findIndex(w => w === strippedWord);
                  const newValue = [...answer.slice(0, index), ...answer.slice(index + 1)];
                  setAnswer(newValue, newValue.length > 0);
                  if (setTip) setTip(newValue);
                } else if (max === 1) {
                  setAnswer([strippedWord], true);
                  if (setTip) setTip([strippedWord]);
                } else if (max === null || answer.length < max) {
                  setAnswer([...answer, strippedWord], true);
                  if (setTip) setTip([...answer, strippedWord]);
                }
              }}
            >
              <span className="word">
                <span dangerouslySetInnerHTML={{ __html: highlightEmotion(word) }} />
                {' '}
                <span dangerouslySetInnerHTML={{ __html: suffixes[wordIndex] }} />
              </span>
            </Word>
          );
        })}
      </WordContainer>
      <NewWordModal
        onClose={toggleModal}
        onAddWord={(word) => {
          setWords([...words, word]);
          if (max === null || answer.length < max) {
            setAnswer([...answer, word], true);
          }
          toggleModal();
        }}
        content={modalContent}
        isOpen={isModalOpen}
      />
    </Split>
  );
};

export default WordPickerList;
