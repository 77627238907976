import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Container } from '@cerebral/react';
import { Element } from 'react-scroll';

import { Helmet } from 'react-helmet';
import GlobalStyles from './styles/global';
import theme from './styles/theme';
import controller from './store/controller';
import Page from './pages';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container controller={controller}>
        <>
          <Helmet>
            <script>
              {`var _paq = window._paq = window._paq || [];
              _paq.push(['trackPageView']);
              (function() {
                var u="https://vad.matomo.cloud/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '5']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src='//cdn.matomo.cloud/vad.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
              })();`}
            </script>
          </Helmet>
          <Element name="anchor" />
          <GlobalStyles />
          <Page />
        </>
      </Container>
    </ThemeProvider>
  );
}

export default App;
