import React from 'react';

import StyledLoader, { StyledSpinner } from './styles';

const Loader = () => (
  <StyledLoader className="loader">
    <StyledSpinner />
  </StyledLoader>
);

export default Loader;
