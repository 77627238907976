import styled, { css } from 'styled-components';
import media from '../../styles/media';

export const StatementCounter = styled.h6`
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 0.75em;
  color: ${props => props.theme.partorange};
`;

export const Statement = styled.h1`
  font-size: 1.125em;
  line-height: 1.416666667;
  color: #333333;
  margin-bottom: 1em;
  letter-spacing: 0.2px;
  font-weight: 500;

  ${media.md`
  font-size: 1.875em;
  `};
  ${props => props.placeholder
    && css`
      display: none;
    `};

  ${media.lg`
  ${props => props.placeholder
    && css`
      opacity: 0;
      display: block;
    `};
  ${props => props.width
    && css`
      width: ${props.width || 50}%;
    `};
    `};
`;

export const StatementSubtitle = styled.h3`
  color: ${props => props.theme.color};
`;

export const StyledSlider = styled.div`
  margin-bottom: 25px;

  padding: 15px;
`;

export const Ticks = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  margin: 0 auto;
`;

export const Tick = styled.div`
  position: relative;
  z-index: 0;

  font-size: 0.775em;
  font-weight: 600;
  cursor: pointer;

  span {
    position: absolute;
    width: 50px;
    left: -25px;
    display: inline-block;
    text-align: center;
    line-height: 1.3;

    font-weight: ${props => (props.isActive ? 600 : 'normal')};
    color: ${props => (props.isActive ? props.theme.primaryDark : props.theme.color)};

    will-change: color;
    transition: color 100ms ease-in-out;
    margin-left: 2px;
  }

  &:after {
    position: absolute;
    top: -8px;
    left: 50%;
    content: '';
    display: block;
    width: 1px;
    height: 5px;
    background-color: #d9d9e4;
    margin-left: 1px;
  }
`;

export const StyledRangeInput = styled.input`
  &&&::-webkit-slider-thumb {
    box-shadow: inset 0 0 0 10px ${props => props.theme.primary};
  }
`;
