import React, { useState } from 'react';

import Button from '../../Button';
import WordPicker from '../Wordpicker';
import Footer from '../../Footer';
import { findAvatar, CDN } from '../../../constants';
import { Splitview, Split, SplitContainer } from '../../Split';

type NetworkWordPickerProps = {
  title: String,
  setAnswer: Function,
  answer: Array,
  words: Array,
  dependData: Array,
  nextButton: any,
  centerButton: any,
  previousButton: any,
};

const NetworkWordPicker = ({
  title,
  setAnswer,
  answer = [],
  words,
  dependData,
  nextButton,
  centerButton,
  previousButton,
  ...props
}: NetworkWordPickerProps) => {
  const [personIndex, setPersonIndex] = useState(
    answer && answer.length > 0 ? answer.filter(Boolean).length - 1 : 0,
  );
  const { name, image } = dependData[personIndex];
  const { src } = findAvatar(image);
  const isValid = answer[personIndex] && answer[personIndex].length;

  return (
    <Splitview>
      <SplitContainer>
        <Split width={100}>
          <h2 className="question-heading">{title.split('{PERSON_NAME}').join(name)}</h2>
          <div className="question-avatar">
            {src.indexOf('http') < 0 ? (
              <img src={`${CDN}assets/avatar/${src}`} alt="avatar" />
            ) : (
              <img src={src} alt="avatar" />
            )}
          </div>
          <WordPicker
            words={[...new Set([...words, ...answer.flat()])]}
            answer={answer[personIndex]}
            setAnswer={(pickedWords) => {
              const newValue = [
                ...answer.slice(0, personIndex),
                pickedWords,
                ...answer.slice(personIndex + 1),
              ];

              setAnswer(
                newValue,
                newValue.length === dependData.length
                  && !newValue.find(personWords => personWords.length === 0),
              );
            }}
            {...props}
          />
          <Footer>
            <div className="footer-center">{centerButton}</div>
            {dependData.length > 0 && (
              <div className="footer-left">
                {personIndex === 0 ? (
                  previousButton
                ) : (
                  <Button
                    disabled={personIndex === 0}
                    onClick={() => setPersonIndex(personIndex - 1)}
                    light
                    direction="previous"
                    color="yellow"
                  >
                    Vorige persoon
                  </Button>
                )}
              </div>
            )}
            {dependData.length > 0 && (
              <div className="footer-right">
                {personIndex === dependData.length - 1 ? (
                  nextButton
                ) : (
                  <Button
                    disabled={!isValid}
                    onClick={() => setPersonIndex(personIndex + 1)}
                    light
                    direction="next"
                    color="yellow"
                  >
                    Volgende persoon
                  </Button>
                )}
              </div>
            )}
          </Footer>
        </Split>
      </SplitContainer>
    </Splitview>
  );
};

export default NetworkWordPicker;
