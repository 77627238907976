import MultipleChoice from './MultipleChoice';
import Wordpicker from './Wordpicker';
import Endscreen from './Endscreen';
import NetworkPicker from './NetworkPicker';
import NetworkWordPicker from './NetworkWordPicker';
import NetworkOverview from './NetworkOverview';
import Conclusion from './Conclusion';
import HelpOverview from './HelpOverview';
import WordpickerList from './WordpickerList';
import WordpickerListExplainer from './WordpickerListExplainer';
import WizardPicker from './WizardPicker';

export default {
  MultipleChoice,
  Wordpicker,
  Endscreen,
  NetworkPicker,
  NetworkWordPicker,
  NetworkOverview,
  Conclusion,
  HelpOverview,
  WordpickerList,
  WordpickerListExplainer,
  WizardPicker,
};
