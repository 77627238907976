import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const WordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Word = styled.div`
  margin-bottom: 30px;
  padding: 10px 30px 10px 30px;
  font-size: 1em;
  font-weight: ${props => (props.isActive ? 600 : 'normal')};
  cursor: pointer;
  opacity: ${props => (props.isDisabled && !props.isActive ? '0.5' : '1')};
  position: relative;

  width: 100%;

  ${media.md`
    width: 50%;
  `};

  .emoji{
    font-size:1.5em;
    transform: translate(0,3px);
    display:inline-block;
    line-height: 1em;
  }

  /* CHECKBOX */
  strong{
    color: ${props => props.theme.partgreen};
  }

  &:before{
    content:'';
    position: absolute;
    left: 0;
    top: 15px;
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.theme.partgreen};
  }

  ${props => props.isActive
    && css`
      &:after{
        content:'';
        border: 2px solid ${props.theme.partgreen};
        border-left: none;
        border-top: none;
        position: absolute;
        left: 7px;
        top: 19px;
        width: 6px;
        height: 10px;
        transform: rotate(40deg);
      }
    `};
`;

export default WordContainer;
