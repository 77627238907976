import styled from 'styled-components';
import media from '../../../styles/media';

export const ConclusionContainer = styled.div`
  background-repeat: no-repeat;
  background-position: right bottom;
  display:flex;
  align-items: center;
  text-align:center;
  background-size: contain;
`;

export const ConclusionWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;

  ${media.md`
    width: 50%;
  `};
`;

export default ConclusionContainer;
