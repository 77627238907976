import React from 'react';

import {
  PartsIntroWrapper,
  PartsIntroContent,
  WelcomeContainer,
  PartsContentWrapper,
} from './styles';

import PartsPicker from '../PartsPicker';
import Loader from '../Loader';
import { Splitview, Split } from '../Split';
import Icon from '../Icon';
// import { CDN } from '../../constants';

type PartsOverviewProps = {
  title: String,
  description: String,
  notification: String,
  notificationCTA: String,
  introTitle: String,
  intro: String,
  parts: Array,
  loading: Boolean
};

const PartsOverview = ({
  title,
  description,
  notification,
  notificationCTA,
  introTitle,
  intro,
  parts,
  loading,
}: PartsOverviewProps) => (loading ? (
  <Loader />
) : (
  <Splitview>
    <Split left background="partlightyellow">
      <PartsIntroWrapper>
        {/* <PartsIntroSpeechBubble>
          <img alt="" src={`${CDN}assets/speechbubble@2x.png`} />
        </PartsIntroSpeechBubble> */}
        <PartsIntroContent>
          <WelcomeContainer>
            <h1 className="welcome-heading">{title}</h1>
            <div className="welcome-description">
              <p
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
              />
            </div>
          </WelcomeContainer>

        </PartsIntroContent>
      </PartsIntroWrapper>
    </Split>
    <Split right>
      <PartsContentWrapper>
        <h2 className="parts-heading">{introTitle}</h2>
        <div className="parts-description">
          <p
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          />
        </div>
        <PartsPicker parts={parts} />
        <div className="alert alert-info">
          {/* <Icon name="lamp" /> */}
          <p>
            <strong>{notification}</strong>
          </p>
          <span className="alert-link-wrapper">
            <Icon name="eye" />
            <a href="#/poster">{notificationCTA}</a>
          </span>
        </div>
      </PartsContentWrapper>
    </Split>
  </Splitview>
));

export default PartsOverview;
