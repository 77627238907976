import React, { useState } from 'react';

import { state, signal } from 'cerebral/tags';
import { connect } from '@cerebral/react';
import AvatarModal from '../../AvatarModal';
import AvatarForm from '../../AvatarForm';
import { AvatarContainer, AddButton } from './styles';
import { Splitview, Split } from '../../Split';
import Icon from '../../Icon';

type NetworkPickerProps = {
  title: String,
  subTitle: String,
  setAnswer: Function,
  answer: Array,
  setTip: Function,
  part: String,
  section: String,
  allAnswers: Array,
  answerSet: Function,
};

const NetworkPicker = ({
  title,
  subTitle,
  setAnswer,
  answer = [],
  setTip,
  part,
  section,
  allAnswers,
  answerSet,
}: NetworkPickerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Splitview>
      <Split width={100}>
        <h2 className="question-heading">{title}</h2>
        <div className="question-description">
          <p dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>

        <AvatarContainer>
          {answer.map((person, index) => (
            <AvatarForm
              key={index}
              handleRemove={() => {
                // update depend answer
                const dependAnswers = allAnswers[part][`${section}-important`];
                if (dependAnswers) {
                  const newDependAnswer = [
                    ...dependAnswers.slice(0, index),
                    ...dependAnswers.slice(index + 1),
                  ];
                  answerSet({
                    part,
                    section: `${section}-important`,
                    answer: newDependAnswer,
                  });
                }

                // submit answer
                const newAnswer = [...answer.slice(0, index), ...answer.slice(index + 1)];
                setAnswer(newAnswer, newAnswer.length !== 0 && !newAnswer.find(({ name }) => name <= 3));
                setTip(newAnswer);
              }}
              handleChange={(name) => {
                const newAnswer = [
                  ...answer.slice(0, index),
                  {
                    ...person,
                    name,
                  },
                  ...answer.slice(index + 1),
                ];
                setAnswer(newAnswer, name.length >= 2 && !newAnswer.find(({ name }) => name < 2));
                setTip(newAnswer);
              }}
              index={index}
              {...person}
            />
          ))}
          <AddButton
            className={answer.length === 0 ? 'small' : 'large'}
            onClick={() => setIsModalOpen(true)}
          >
            <Icon name="plus" />
            <span>Voeg iemand toe</span>
          </AddButton>
        </AvatarContainer>

        <AvatarModal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          handleSelect={(slug) => {
            setAnswer(
              [
                ...answer,
                {
                  image: slug,
                  name: '',
                },
              ],
              false,
            );
            setIsModalOpen(false);
          }}
        />
      </Split>
    </Splitview>
  );
};

const stateSignals = {
  allAnswers: state`app.answers`,
  answerSet: signal`app.answerSet`,
};

export default connect(
  stateSignals,
  NetworkPicker,
);
