import styled from 'styled-components';
import media from '../../styles/media';

export const StyledHeader = styled.div`
  position: relative;
  background: ${props => props.theme[props.background]};
  display: flex;

  height: 63px;
`;

export const StyledPartInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme[props.background]};
  padding: 15px;

  ${media.md`
    padding: 25px;
  `};
`;

export const StyledPartSubHeading = styled.h6`
  margin: 0;
  color: black;
  font-size: 0.75em;
  font-weight: 300;
`;

export const StyledPartHeading = styled.h5`
  display: none;
  color: black;
  margin: 0;
  font-size: 2rem;
  font-weight: 500;
  line-height: 18px;

  ${media.md`
    display: block;
  `};
`;
