import React from 'react';
import Avatar from './styles';
import { findAvatar, CDN } from '../../constants';

type AvatarProps = {
  slug: String,
  name: String,
};

export default ({ slug, name }: AvatarProps) => {
  const { src } = findAvatar(slug);

  return (
    <Avatar>
      {src.indexOf('http') < 0 ? (
        <img src={`${CDN}assets/avatar/${src}`} alt={slug} />
      ) : (
        <img src={src} alt={slug} />
      )}
      <span>{name}</span>
    </Avatar>
  );
};
