import styled, { keyframes } from 'styled-components';
import media from '../../styles/media';

const fadeIn = keyframes`
    from {
      background-color: rgba(#fff, 0.6);
    }
    to {
      background-color: rgba(#fff, 0.75);
    }
`;

export const Root = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  overflow: auto;

  animation-name: ${fadeIn};
  animation-duration: 300ms;

  background-color: rgba(0, 0, 0, 0.6);

  align-items: center;
  justify-content: center;
`;

export const Dialog = styled.div`
  position: relative;
  width: 100%;

  animation-name: slideIn;
  animation-duration: 300ms;

  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  max-width: 90vw;
  max-height: 90vh;

  display: flex;
  flex-direction: column;

  ${media.md`
  max-width: 60vw;
  margin: 2em;`};
`;

export const ModalHeader = styled.div`
  padding: 1.5em;
  background-color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
    text-align: center;
  }
`;

export const ModalBody = styled.div`
  padding: ${props => (props.noPadding ? 'initial' : '1.5em')};
  max-height: 70vh;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  border-top: 2px solid #eceff0;
`;

export const CloseButtonContainer = styled.button`
  position: absolute;
  border: none;
  background: none;
  outline: none;
  top: 11px;
  right: 10px;
`;
