import styled from 'styled-components';
import media from '../../styles/media';

export const QuestionView = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;

  ${media.lg`
    flex-direction: row;
  `};
`;

export default QuestionView;
