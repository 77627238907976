export default async function ({ path, state, api }) {
  const id = state.get('app.id');
  const parts = state.get('app.data.parts');

  try {
    const response = await api.get(`/state/${id}`, {});
    const { data } = JSON.parse(response.data);
    const answers = JSON.parse(data);
    state.set('app.answers', answers || {});

    const newParts = parts.map((part) => {
      const { slug, sections } = part;
      if (answers[slug]) {
        const questionSections = sections.filter(({ hasAnswer }) => hasAnswer);

        if (questionSections.length === Object.keys(answers[slug]).length) {
          return { ...part, isFinished: true };
        }
      }

      return part;
    });

    state.set('app.data.parts', newParts);

    return path.success({});
  } catch (error) {
    return path.error({ error });
  }
}
