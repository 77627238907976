import { css } from 'styled-components';

export const breakpoints = {
  sm: '36em',
  md: '48em',
  lg: '62em',
  xl: '75em',
};

const sizes = {
  sm: `min-width: ${breakpoints.sm}`,
  onlySm: `min-width: ${breakpoints.sm} and max-width: ${breakpoints.md}`,
  md: `min-width: ${breakpoints.md}`,
  onlyMd: `min-width: ${breakpoints.md} and max-width: ${breakpoints.lg}`,
  lg: `min-width: ${breakpoints.lg}`,
  onlyLg: `min-width: ${breakpoints.lg} and max-width: ${breakpoints.xl}`,
  xl: `min-width: ${breakpoints.xl}`,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (${sizes[label]}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default {
  sm: media.sm,
  md: media.md,
  lg: media.lg,
  xl: media.xl,
  onlySm: media.onlySm,
  onlyMd: media.onlyMd,
  onlyLg: media.onlyLg,
};
