import styled from 'styled-components';
import media from '../../styles/media';

export const Page = styled.div`
  position: relative;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  max-width: 100%;

  ${media.lg`
    margin: 16px 0px;
    width: 100%;
    min-height: calc(100vh - (2 * 85px));
  `};
`;

export default Page;
